import SponsorshipRepository from "../Repositories/SponsorshipRepository";
import { CreateSponsorship } from '../../Types/Sponsorship';
import {useQuery} from "@tanstack/react-query";
import { Pagination } from "../../Types/Paginator";

export const useGetAllSponsorships = (pagination: Partial<Pagination> = {}, orphanId?: string) => {
    const filters = orphanId ? { ...pagination, filter: { "orphan.id": orphanId } } : pagination;

    return useQuery({
        queryKey: ['sponsorships', pagination, orphanId],
        queryFn: () => SponsorshipRepository.getAll(filters),
    });
};

export const useGetSponsorshipById = (id: string) => {
    return useQuery({
        queryKey: ['sponsorships', id],
        queryFn: async () => {
            return await SponsorshipRepository.getById(id);
        }
    });
};

export const createSponsorship = async (newSponsorship: CreateSponsorship) => {
    return await SponsorshipRepository.create(newSponsorship);
};

export const updateSponsorship = async (id: string, updatedSponsorship: CreateSponsorship) => {
    return await SponsorshipRepository.update(id, updatedSponsorship);
};

export const deleteSponsorship = async (id: string) => {
    return await SponsorshipRepository.delete(id);
};
