import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from './Pages/Routes';
import { ReactNotifications } from 'react-notifications-component'
import Login from "./Pages/Login/Login";
import Guard from "./Components/Auth/Guard";
import Layout from "./Pages/Layout";


const App: React.FC = () => {
    return (
        <div>
            <ReactNotifications isMobile />
            <BrowserRouter>
                <Routes>
                    {routes.map((route) => (
                        <Route key={route.route} path={route.route}
                            element={<Guard><Layout><route.page /></Layout></Guard>}
                        />
                    ))}
                    <Route path="/login" element={<Login />}></Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;

