import React from 'react';
import { Grid, Button } from '@mui/material';
import { Form } from 'formik';
import Input from '../../../Components/Form/Input';
import StatusesSelector from '../../../Components/Form/Select/StatusesSelector';
import UploadImage from "../../../Components/Form/ImageComponents/UploadImage";
import DatePickerInput from "../../../Components/Form/DatePicker";
import Select from "../../../Components/Form/Select";
import OrphanageSelector from "../../../Components/Form/Select/OrphanageSelector";
import { educationLevels, genders } from "../../../Components/Const/Transverse";
import ImagePreview from "../../../Components/Form/ImageComponents/ImagePreview";

interface OrphanFormProps {
    isEdit?: boolean;
    imagePreviewUrl?: string | null;
}

const OrphanForm: React.FC<OrphanFormProps> = ({ isEdit = false, imagePreviewUrl }) => {

    return (
        <Form>
            <Grid container spacing={2} rowSpacing={5} direction="column">
                <Grid item>
                    <Input name="firstName" label="Prénom de l'orphelin" />
                </Grid>
                <Grid item>
                    <Input name="lastName" label="Nom de l'orphelin" />
                </Grid>
                <Grid item>
                    <Select name="gender" label="Genre de l'orphelin" options={genders} />
                </Grid>
                <Grid item>
                    <Input name="position" type="number" label="Position" formControlProps={{ fullWidth: true }} />
                </Grid>
                <Grid item>
                    <DatePickerInput label="Date de naissance" name="birthDate" required />
                </Grid>
                <Grid item>
                    <StatusesSelector />
                </Grid>
                <Grid item>
                    <Select name="educationLevel" label="Niveau d'education" options={educationLevels} />
                </Grid>
                <Grid item>
                    <OrphanageSelector name="orphanage" label="Nom de l'Orphelinat" />
                </Grid>
                <Grid item>
                    <UploadImage name="photoFile" label="Ajouter une image" showPreview={true} isMultiple={false} className="UploadImage" />
                    {isEdit && imagePreviewUrl && (
                        <ImagePreview isMultiple={false} images={imagePreviewUrl} />
                    )}
                </Grid>
                <Grid item>
                    <Button type="submit" variant="contained">{isEdit ? 'Modifier' : 'Ajouter'}</Button>
                </Grid>
            </Grid>
        </Form>
    );
};

export default OrphanForm;
