
import React, {useMemo} from 'react';
import { useGetAllOrphanage } from '../../../Api/Queries/OrphanageQuery';
import Select, { SelectProps } from "../Select";
import { SelectOption } from "../../../Types/Transverse";

interface OrphanageSelectorProps extends Omit<SelectProps, 'options'> {
    name: string;
    label: string;
    onSelect?: (orphanageId: string) => void;
}

const OrphanageSelector: React.FC<OrphanageSelectorProps> = ({ label, name, onSelect, ...props }) => {
    const { data, isLoading } = useGetAllOrphanage();
    const options: SelectOption[] = useMemo(() => {
        if (isLoading || !data?.results) return [];

        return data.results.map(orphanage => ({
            label: `${orphanage.name} - ${orphanage.city.nameFr}`,
            value: orphanage.id as string
        }));
    }, [data, isLoading]);

    return (
        <Select
            emptyText="Aucun Orphelinat" name={name} label={label} options={options} onSelect={onSelect}{...props}
            isLoading={isLoading}
        />
    );
};

export default OrphanageSelector;
