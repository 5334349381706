import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import ApiTable from "../../../Components/DataDisplay/ApiTable";
import { useGetAllAdmin } from "../../../Api/Queries/AdminQuery";
import DeleteAdmin from "../Delete/DeleteAdmin";
import { Admin } from "../../../Types/Admin";
import { adminColumns } from "./Tablet";
import { useToggle } from "../../../Hooks/Transverse"; 

const AdminList: React.FC = () => {
    const [selectedAdmin, setSelectedAdmin] = useState<Admin | null>(null);
    const { isOn: isShownModalDelete, on: showModalDelete, off: hideModalDelete } = useToggle(false);
    const { refetch } = useGetAllAdmin();

    const handleDeleteClick = (admin: Admin) => {
        setSelectedAdmin(admin);
        showModalDelete();
    };

    const handleCloseDeleteDialog = () => {
        setSelectedAdmin(null);
        hideModalDelete();
    };

    return (
        <Box>
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Liste des Admins</Typography>
            </Grid>

            <Grid container justifyContent="end" marginY={1}>
                <Grid>
                    <Link to="/admins/create">
                        <Button type="button" variant="contained" color="primary">Ajouter un admin</Button>
                    </Link>
                </Grid>
            </Grid>

            <ApiTable query={useGetAllAdmin} columns={adminColumns({ onDeleteClick: handleDeleteClick })} />

            {selectedAdmin && (
                <DeleteAdmin
                    admin={selectedAdmin}
                    open={isShownModalDelete}
                    onClose={handleCloseDeleteDialog}
                    onDelete={refetch}
                />
            )}
        </Box>
    );
};

export default AdminList;
