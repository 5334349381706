const TitleConfig = {
    toolbar: [
        'heading', 
        '|',
        'bold',    
        'italic'   
    ],
};

export default TitleConfig;
