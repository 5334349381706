import React from 'react';
import OrphanageCreate from "./Orphanage/Create/OrphanageCreate";
import OrphanageList from "./Orphanage/List/OrphanageList";
import Home from "./Home/Home";
import EditOrphanage from "./Orphanage/Edit/OrphanageTabs/OrphanageInfoTabs";
import CountryEditPage from "./Country/Edit/CountryEditPage";
import CountryCreatePage from "./Country/Create/CountryCreatePage";
import CountryListPage from "./Country/List/CountryListPage";
import CityCreatePage from "./City/Create/CityCreatePage";
import CityListPage from "./City/List/CityListPage";
import CityEditPage from "./City/Edit/CityEditPage";
import OrphanCreate from "./Orphan/Create/OrphanCreate";
import AdminList from './Admin/List/AdminList';
import AdminCreatePage from './Admin/Create/AdminCreatePage';
import EditOrphanFile from "./Orphan/Edit/EditOrphan";
import OrphanListPage from "./Orphan/List/OrphanListPage";
import AdminEditPage from './Admin/Edit/AdminEditPage';
import FaqList from './Faq/List/FaqList';
import FaqCreate from './Faq/Create/FaqCreate';
import FaqEdit from './Faq/Edit/FaqEdit';
import ContactListPage from "./Contact/List/ContactListPage";
import ContactView from "./Contact/View/ContactView";
import OrphanTabs from './Orphan/Edit/Tabs/OrphanTabs';
import VideoList from './Orphanage/Edit/Video/List/VideoList';
import OrphanageEdit from './Orphanage/Edit/OrphanageEdit';

interface RouteConfig {
    route: string;
    page: React.ComponentType<any>;
    roles?: string[];
}

const orphanageRoutes: RouteConfig[] = [
    {
        route: '/orphanages',
        page: OrphanageList,
    },
    {
        route: '/orphanages/create',
        page: OrphanageCreate,
    },
    {
        route: '/videos',
        page: VideoList,
    },
    {
        route: '/orphanages/edit/:id',
        page: EditOrphanage,
    },
    {
        route: '/orphanages/:id',
        page: OrphanageEdit,
    }
];

const countryRoutes: RouteConfig[] =  [
    {
        route: '/countries',
        page: CountryListPage,
    },
    {
        route: '/countries/create',
        page: CountryCreatePage,
    },
    {
        route: '/countries/edit/:id',
        page: CountryEditPage,
    },
]

const citiesRoutes: RouteConfig[] =  [
    {
        route: '/cities',
        page: CityListPage,
    },
    {
        route: '/cities/create',
        page: CityCreatePage,
    },
    {
        route: '/cities/edit/:id',
        page: CityEditPage,
    },
]

const orphanRoutes: RouteConfig[] =  [
    {
        route: '/orphans',
        page: OrphanListPage,
    },
    {
        route: '/orphan/create',
        page: OrphanCreate,
    },
    {
        route: '/orphan/edit/:id',
        page: EditOrphanFile,
    },
    {
        route: '/orphan/:id',
        page: OrphanTabs,
    }
]

const transverseRoutes: RouteConfig[] = [
    {
        route: '/',
        page: Home,
    }
]

const adminsRoutes: RouteConfig[] = [
    {
        route: '/admins',
        page: AdminList,
    },
    {
        route: '/admins/create',
        page: AdminCreatePage,
    },
    {
        route: '/admins/edit/:id',
        page: AdminEditPage,
    },
]

const faqsRoutes: RouteConfig[] = [
    {
       route: '/faqs',
       page: FaqList,
    },
    {
        route: '/faqs/create',
        page: FaqCreate,
    },
    {
        route: '/faqs/edit/:id',
        page: FaqEdit,
    }

]

const priseContactRoutes: RouteConfig[] = [
    {
        route: '/contacts',
        page: ContactListPage,
    },
    {
        route: '/contacts/view/:id',
        page: ContactView,
    }


]


export const routes: RouteConfig[] = [
    ...orphanageRoutes,
    ...transverseRoutes,
    ...countryRoutes,
    ...citiesRoutes,
    ...orphanRoutes,
    ...adminsRoutes,
    ...faqsRoutes,
    ...priseContactRoutes
];