import React from 'react';
import {CreateOrphan} from '../../../Types/Orphan';
import { StatusEnum, EducationLevelEnum, GenderEnum } from '../../../Types/Enum';
import { Grid, Typography} from "@mui/material";
import { Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {notify, notifyError} from "../../../Components/Utils/Notifications";
import {createOrphan} from "../../../Api/Queries/OrphanQuery";
import OrphanForm from "../Form/OrphanForm";

const OrphanCreate: React.FC = () => {

    const initialOrphan: CreateOrphan = {
        photoFile: null,
        firstName:'',
        lastName: '',
        birthDate: '',
        educationLevel: EducationLevelEnum.PRIMARY,
        position: 0,
        status: StatusEnum.DRAFT,
        orphanage: '',
        gender: GenderEnum.Female,
    };

    const validationSchema = Yup.object().shape({
        lastName: Yup.string().required('Le nom est requis'),
        firstName: Yup.string().required('Le prénom est requis'),
        birthDate: Yup.date().required('La date de naissance est requise').nullable(),
        educationLevel: Yup.string().oneOf(Object.values(EducationLevelEnum)).required('Le niveau scolaire est requis'),
        position: Yup.number().required('La position est requise'),
        status: Yup.string().oneOf(Object.values(StatusEnum)).required('Le statut doit être renseigné'),
        gender: Yup.string().oneOf(Object.values(GenderEnum)).required('Le genre doit être renseigné'),
    });

    const create = async (values: CreateOrphan, actions: FormikHelpers<CreateOrphan>) => {
        try {
            const formData = new FormData();
            formData.append('firstName', values.firstName);
            formData.append('lastName', values.lastName);
            formData.append('birthDate', values.birthDate);
            formData.append('educationLevel', values.educationLevel);
            formData.append('position', values.position.toString());
            formData.append('status', values.status);
            formData.append('gender', values.gender);
            if (values.orphanage) {
                formData.append('orphanage', values.orphanage);
            }
            if (values.photoFile) {
                formData.append('photoFile', values.photoFile);
            }

            await createOrphan(formData);
            notify('Fichier orphelin créé avec succès');
        } catch (error) {
            notifyError('Erreur lors de la création de fichier orphelin');
        }
    };

    return (
        <Grid container direction="column">
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Ajouter un nouveau orphelin</Typography>
            </Grid>

            <Grid item>
                <Formik initialValues={initialOrphan} onSubmit={create} validationSchema={validationSchema}>
                    <OrphanForm />
                </Formik>
            </Grid>
        </Grid>
    );
};

export default OrphanCreate;