import {useQuery} from "@tanstack/react-query";
import { Pagination } from "../../Types/Paginator";
import ContactRepository from "../Repositories/ContactRepository";

export const useGetAllContact = (pagination: Partial<Pagination> = {}) => useQuery ({
    queryKey: ['contacts',pagination],
    queryFn: () => ContactRepository.getAll(pagination),
});

export const deleteContact = async (id: string) =>{
    return await ContactRepository.delete(id);
};

export const useGetContactById = (id: string) => {
    return useQuery({
        queryKey: ['contact', id],
        queryFn: async () => {
            return await ContactRepository.getById(id);
        }
    });
};

