import React from 'react';
import { Box, Grid, Typography,Button } from '@mui/material';
import { useParams } from 'react-router-dom'; 
import ApiTable from "../../../Components/DataDisplay/ApiTable";
import { orphanColumns } from "./Table";
import { useGetAllOrphansByOrphanage } from "../../../Api/Queries/OrphanQuery"; 
import {GridPaginationModel} from "@mui/x-data-grid";
import { useToggle } from '../../../Hooks/Transverse';
import ModalOrphanAssignment from '../../Orphanage/Edit/OrphanWithoutOrphanage/OrphanAssignment/ModalOrphanAssignment';

const OrphanListOrphanage: React.FC = () => {
    const {id} = useParams<{ id?: string}>();
    const orphanQuery = useGetAllOrphansByOrphanage(id);
    const { isOn: isShownOrphanCreate, on: showOrphanCreate, off: hideOrphanCreate } = useToggle(false);

    return (
        <Box>
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Liste des orphelins</Typography>
            </Grid>
         
            <Grid container justifyContent="end" marginY={1}>
                <Grid item>
                   <Button type="button" variant="contained" color="primary" onClick={showOrphanCreate}>
                    Ajouter un orphelin
                    </Button>
                </Grid>
                <ModalOrphanAssignment open={isShownOrphanCreate} onClose={hideOrphanCreate} orphanage={id!} />
            </Grid>
            
            <ApiTable 
                query={(pagination: GridPaginationModel) => orphanQuery} 
                columns={orphanColumns} 
            />
           
        </Box>

    );
};

export default OrphanListOrphanage;
