import React from "react";
import {notify} from "../../../Components/Utils/Notifications";
import Loading from "../../../Components/Feeback/Loading";
import {useParams} from "react-router-dom";
import {pick} from "lodash";
import {Grid, Typography} from "@mui/material";
import CityForm from "../Form/CityForm";
import {updateCity, useGetCityById} from "../../../Api/Queries/CityQuery";
import {CreateCity} from "../../../Types/City";

type RouteParams = keyof {
    id: string;
}

const CityEditPage: React.FC = () => {
    const { id } = useParams<RouteParams>();
    const {isLoading, isSuccess, data: city} = useGetCityById(id!);

    const handleSubmit = (values: CreateCity) => {
        const city = pick(values, ['nameFr', 'nameEn', 'status', 'position', 'country']);
        updateCity(id!, city).then(() => {
            notify('Pays mis a jour');
        });
    }

    const defaultValue: Partial<CreateCity> = {
        ...city,
        country: city?.country?.id
    }

    return (
        <>
            <Loading show={isLoading} />
            <Grid container direction="column">
                <Grid item marginY={5}>
                    <Typography align="center" gutterBottom variant="h3">Editer une ville</Typography>
                </Grid>

                <Grid item>
                {
                    isSuccess && <CityForm onSubmit={handleSubmit} defaultValues={defaultValue}/>
                }
                </Grid>
            </Grid>
        </>
    );
}

export default CityEditPage;
