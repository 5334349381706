import React from "react";
import Faq from "../../../Types/Faq";
import { LocaleEnum, StatusEnum } from "../../../Types/Enum";
import { Form, Formik } from "formik";
import { Grid ,Button} from "@mui/material";
import Input from "../../../Components/Form/Input";
import StatusesSelector from "../../../Components/Form/Select/StatusesSelector";
import { locale } from "../../../Components/Const/Transverse";
import Select from "../../../Components/Form/Select";
import * as Yup from "yup";
import CKEditorComponent from "../../../Components/CKEditor/CKEditorComponent";

interface Props {
    defaultValues?: Partial<Faq>;
    onSubmit: (faq: Faq) => void;
}

const FaqForm: React.FC<Props> = ({ defaultValues, onSubmit }) => {
    const initialValues: Faq = {
        locale: LocaleEnum.FR,
        status: StatusEnum.DRAFT,
        position: 0,
        question:'',
        response:'',
        ...defaultValues
    }

    const validationSchema = Yup.object().shape({
        question: Yup.string().required('La question est requise'),
        response: Yup.string().required('La réponse est requise'),
        position: Yup.number().required('La position est requise'),
        status: Yup.string().required('Le statut est requis'),
        locale: Yup.string().required('La locale est requise')
    });

    const handleSubmit =  async (values: Faq) => {
        onSubmit(values);
    };

    return(
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {
                <Form>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <CKEditorComponent label="Question" name="question"/>
                        </Grid>
                        <Grid item>
                            <Input label="Response" name="response" />
                        </Grid>
                        <Grid item>
                            <Input label="Position" name="position" type="number" />
                        </Grid>
                        <Grid item>
                            <StatusesSelector />
                        </Grid>
                        <Grid item>
                            <Select name="locale" label="Locale" options={locale} />
                        </Grid>
                        <Grid item container justifyContent="center">
                            <Button type="submit" variant="contained">Valider</Button>
                        </Grid>
                    </Grid>
                </Form>
            }
        </Formik>
    );
}

export default FaqForm;
