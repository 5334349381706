import React from 'react';
import { Formik, Form } from 'formik';
import { User } from '../../../../../Types/User';
import { CreateSponsorship } from '../../../../../Types/Sponsorship';
import { createSponsorship } from '../../../../../Api/Queries/SponsorshipQuery'; 
import { notify, notifyError } from "../../../../../Components/Utils/Notifications";
import SearchUser from './SearchUser';
import DatePickerInput from "../../../../../Components/Form/DatePicker";
import { Box, Button } from '@mui/material';

interface AddSponsorFormProps {
    open: boolean;
    onClose: () => void;
    orphan: string;
}

const AddSponsorForm: React.FC<AddSponsorFormProps> = ({ open, onClose, orphan }) => {
    const initialValues = {
        selectedUser: null,
        startDate: '',
        endDate:  '',
    };

    const handleSubmit = async (values: {
        selectedUser: User | null;
        startDate: string;
        endDate: string;
    }) => {
        try {
            if (values.selectedUser) {
                const sponsorship: CreateSponsorship = {
                    user: values.selectedUser.id,
                    orphan,
                    startDate: values.startDate,
                    endDate: values.endDate,
                };
                await createSponsorship(sponsorship);
                notify('Lien de parrainage créé avec succès!');
                onClose(); 
            }
        } catch (error) {
            notifyError('Erreur lors de la création');
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
                <Box display="flex" flexDirection="column" sx={{ gap: 2 }}>
                    <SearchUser name="selectedUser" />
                    <DatePickerInput label="Start Date" name="startDate" required />
                    <DatePickerInput label="End Date" name="endDate" required />
                    <Button type="submit" variant="contained" color="primary">
                        Valider
                    </Button>
                </Box>
            </Form>
        </Formik>
    );
};

export default AddSponsorForm;
