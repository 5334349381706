import React, {useState} from 'react';
import {Formik, Form} from 'formik';
import {Box, Button, Container, Fab, Grid} from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import * as Yup from 'yup';
import {StatusEnum} from "../../../Types/Enum";
import {CreateOrphanage} from "../../../Types/Orphanage";
import CountrySelector from "../../../Components/Form/Select/CountrySelector";
import CitySelector from "../../../Components/Form/Select/CitySelector";
import Input from "../../../Components/Form/Input";
import {useToggle} from "../../../Hooks/Transverse";
import StatusesSelector from "../../../Components/Form/Select/StatusesSelector";
import {useQueryClient} from "@tanstack/react-query";
import {notify, notifyError} from "../../../Components/Utils/Notifications";
import {createOrphanage} from "../../../Api/Queries/OrphanageQuery";
import ModalCountryCreate from "../../Country/Create/ModalCountryCreate";
import ModalCityCreate from "../../City/Create/ModalCityCreate";
import './OrphanageCreate.scss';

const OrphanageCreate: React.FC = () => {
    const {isOn: isShownCountryCreate, on: showCountryCreate, off: hideCountryCreate} = useToggle(false);
    const {isOn: isShownCityCreate, on: showCityCreate, off: hideCityCreate} = useToggle(false);
    const queryClient = useQueryClient();

    const initialOrphanage: CreateOrphanage = {
        name: '',
        position: 0,
        status: StatusEnum.DRAFT,
        city: '',
        country: '',
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Le nom est requis'),
        position: Yup.number().required('La position est requise'),
        status: Yup.string().required('Le status doit etre renseigne'),
        city: Yup.string().required('Veuillez selectionner une ville'),
    });

    const create = async (values: CreateOrphanage) => {
        try {
            const updatedValues = {
                ...values,
             };

            await createOrphanage(updatedValues);
            notify('Orphelinat cree avec succes');
        } catch (error) {
            notifyError('Erreur lors de la creation de l orphelinat');
        }
    };

    const refreshCountries = () => queryClient.refetchQueries({queryKey: ['countries']});
    const refreshCitiesOfCountry = (countryId: string) => () => queryClient.refetchQueries({queryKey: ['cities', countryId]});

    return (
        <Box className='box'>
            <Container fixed>
                <Formik initialValues={initialOrphanage} onSubmit={create} validationSchema={validationSchema}>
                    {
                        ({values}) => {
                            return (<Form>
                                <Grid container spacing={2} rowSpacing={5} direction="column">
                                    <Grid item>
                                        <h2>Ajouter un projet</h2>
                                    </Grid>
                                    <Grid item>
                                        <Input name="name" label="Nom de l'orphelinat"/>
                                    </Grid>
                                    <Grid item>
                                        <Input name="position" type="number" label="Position" formControlProps={{fullWidth: true}}/>
                                    </Grid>

                                    <Grid item>
                                        <StatusesSelector />
                                    </Grid>

                                    <Grid item>
                                        <CountrySelector name="country" label="Pays de l'orphelinat"/>

                                        <Grid item container justifyContent="end" marginBlockStart={2}>
                                            <Fab color="primary" size="small" variant="extended" aria-label="add" onClick={showCountryCreate}>
                                                <AddIcon/>
                                                Ajouter un pays
                                            </Fab>
                                        </Grid>

                                        <ModalCountryCreate open={isShownCountryCreate} onClose={hideCountryCreate} onCreate={refreshCountries}/>
                                    </Grid>

                                    <Grid item>
                                        <CitySelector name="city" label="Ville" countryId={values.country} selectProps={{disabled: values.country === ''}}/>

                                        <Grid item container justifyContent="end" marginBlockStart={2}>
                                            <Fab color="primary" size="small" variant="extended" aria-label="add" onClick={showCityCreate}>
                                                <AddIcon/>
                                                Ajouter une ville
                                            </Fab>
                                        </Grid>

                                        <ModalCityCreate open={isShownCityCreate} onClose={hideCityCreate} onCreate={refreshCitiesOfCountry(values.country)}/>
                                    </Grid>

                                    <div className='btn'>
                                        <Button type="submit" variant="contained">Ajouter</Button>
                                    </div>
                                </Grid>

                            </Form>)
                        }
                    }
                </Formik>
            </Container>
        </Box>
    );
};
export default OrphanageCreate;
