import React, { FC } from 'react';
import { useField } from 'formik';
import { InputLabel, FormControl, FormHelperText, FormControlProps, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface DatePickerInputProps {
    label: string;
    name: string;
    formControlProps?: Partial<FormControlProps>;
    required?: boolean;
    dateFormat?: string;
}

const DatePickerInput: FC<DatePickerInputProps> = ({ label, name, formControlProps, required = false, dateFormat = 'YYYY-MM-DD' }) => {
    const [field, meta, helpers] = useField<string>(name);
    const hasError = meta.touched && Boolean(meta.error);

    const handleChange = (date: Dayjs | null) => {
        const formattedDate = date ? date.format(dateFormat) : '';
        helpers.setValue(formattedDate, true);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl fullWidth error={hasError} {...formControlProps}>
                <DatePicker
                    label={label}
                    value={field.value ? dayjs(field.value, dateFormat) : null}
                    onChange={handleChange}
                />
                {hasError && <FormHelperText>{meta.error}</FormHelperText>}
            </FormControl>
        </LocalizationProvider>
    );
};

export default DatePickerInput;