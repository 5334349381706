import dayjs from "dayjs";

/**
 *
 * @param date format accepted by https://day.js.org/docs/en/parse/string
 * @param toFormat
 */
export const dateFormat = (date: string|Date, toFormat = 'DD/MM/YYYY') => {
    return dayjs(date).format(toFormat);
}

export const dateFormatToDate = (date: string|Date) => {
    return dayjs(date).toDate();
}