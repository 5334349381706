import { Grid , Typography } from "@mui/material";
import React from "react";
import AdminCreate from "./AdminCreate";


const AdminCreatePage : React.FC = () => {
    return(
        <Grid container direction={"column"}>
            <Grid item>
                <Typography align="center" gutterBottom variant="h3">Ajouter Admin</Typography>
            </Grid>
            <Grid item>
                <AdminCreate />
            </Grid>
        </Grid>
    );
}
export default AdminCreatePage;