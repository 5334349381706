import React from 'react';
import { deleteVideo } from '../../../../../Api/Queries/VideoQuery';
import Video from '../../../../../Types/Video';
import ConfirmationDialog from "../../../../../Components/Layout/ConfirmationDialog";
import { notify, notifyError } from '../../../../../Components/Utils/Notifications';

interface VideoDeletePageProps {
    video: Video;
    open: boolean;
    onClose: () => void;
    onRefresh: () => void;
}

const DeleteVideo: React.FC<VideoDeletePageProps> = ({ video, open, onClose, onRefresh }) => {
    const handleDelete = async () => {
        try {
            await deleteVideo(video.id);
            onClose();
            onRefresh(); 
            notify('Vidéo supprimée avec succès');
        } catch (error) {
            notifyError('Erreur lors de la suppression de la vidéo');
        }
    };

    return (
        <ConfirmationDialog
            open={open}
            onCancel={onClose}
            title="Supprimer la vidéo"
            onConfirm={handleDelete}
            message="Êtes-vous sûr de vouloir supprimer cette vidéo ?"
        />
    );
};

export default DeleteVideo;
