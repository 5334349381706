import React from 'react';
import { Formik, Form } from 'formik';
import { Grid, Button } from '@mui/material';
import * as Yup from 'yup';
import Video from '../../../../../Types/Video';
import { updateVideo } from '../../../../../Api/Queries/VideoQuery';
import Input from '../../../../../Components/Form/Input';
import Dialog from '../../../../../Components/Layout/Dialog';
import UploadImage from '../../../../../Components/Form/ImageComponents/UploadImage';
import ImagePreview from '../../../../../Components/Form/ImageComponents/ImagePreview';
import { notify, notifyError } from '../../../../../Components/Utils/Notifications';

interface EditVideoModalProps {
    open: boolean;
    onClose: () => void;
    video: Video;
    onRefresh: () => void;
}

const EditVideo: React.FC<EditVideoModalProps> = ({ open, onClose, video, onRefresh }) => {
    const initialValues: Video = {
        ...video!,
    };

    const validationSchema = Yup.object().shape({
        youtubeUrl: Yup.string().required('Lien YouTube requis'),
        titleFr: Yup.string().required('Titre (FR) requis'),
        titleEn: Yup.string().required('Titre (EN) requis'),
    });

    const handleSubmit = async (values: Video) => {
        try {
            const formData = new FormData();
            formData.append('youtubeUrl', values.youtubeUrl);
            formData.append('titleFr', values.titleFr);
            formData.append('titleEn', values.titleEn);
            formData.append('orphanage', values.orphanage.id);
            if (values.mobileImageFile) {
                formData.append('mobileImageFile', values.mobileImageFile!);
            }
            if (values.pcImageFile) {
                formData.append('pcImageFile', values.pcImageFile!);
            }
            await updateVideo(video.id!, formData);
            onClose();
            onRefresh();
            notify("Vidéo modifiée avec succès");
        } catch (error) {
            notifyError("Erreur lors de la modification de la vidéo");
        }
    };

    return (
        <Dialog open={open} onClose={onClose} title="Modifier une vidéo" showClose={true}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    <Form>
                        <Grid container spacing={3}>
                            <Grid item>
                                <Input name="youtubeUrl" type="text" label="URL YouTube" />
                            </Grid>
                            <Grid item>
                                <Input name="titleFr" type="text" label="Titre (FR)" />
                            </Grid>
                            <Grid item>
                                <Input name="titleEn" type="text" label="Titre (EN)" />
                            </Grid>
                            <Grid item>
                            <UploadImage name="mobileImageFile"  label="Ajouter une image (mobile)" showPreview={true} isMultiple={false} className="UploadImage" />
                            <ImagePreview isMultiple={false} images={video.mobileImageUrl}  />
                        </Grid>
                        <Grid item>
                            <UploadImage name="pcImageFile" label="Ajouter une image (ordinateur)" showPreview={true} isMultiple={false} className="UploadImage" />
                            <ImagePreview isMultiple={false} images={video.pcImageFileUrl}  />
                        </Grid>
                            <Grid item xs={10}>
                                <Button type="submit" variant="contained">Modifier vidéo</Button>
                            </Grid>
                        </Grid>
                    </Form>
            </Formik>
        </Dialog>
    );
}

export default EditVideo;
