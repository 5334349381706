import React from 'react';
import { Formik, Form } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import { User } from '../../../../../Types/User';
import { Sponsorship, CreateSponsorship } from '../../../../../Types/Sponsorship';
import { updateSponsorship, useGetSponsorshipById } from '../../../../../Api/Queries/SponsorshipQuery'; 
import { notify, notifyError } from "../../../../../Components/Utils/Notifications";
import SearchUser from '../Create/SearchUser';
import DatePickerInput from "../../../../../Components/Form/DatePicker";
import { Box, Button, CircularProgress } from '@mui/material';

interface EditSponsorshipFormProps {
    onClose: () => void;
    sponsorshipId: string;
}

const EditSponsorshipForm: React.FC<EditSponsorshipFormProps> = ({ onClose, sponsorshipId }) => {
    const { isLoading, data: sponsor } = useGetSponsorshipById(sponsorshipId!);
    if (isLoading) {
        return <CircularProgress />;
    }

    const initialValues = {
        selectedUser: sponsor?.user || null,
        startDate: sponsor ? sponsor.startDate : '',
        endDate: sponsor ? sponsor.endDate : '',
    };

    const handleSubmit = async (values: {
        selectedUser: User | null;
        startDate: string;
        endDate: string;
    }) => {
        try {
            if (values.selectedUser) {
                const updatedSponsorship: CreateSponsorship = {
                    user: values.selectedUser.id,
                    orphan: sponsor!.orphan.id!,
                    startDate: values.startDate,
                    endDate: values.endDate,
                };
                await updateSponsorship(sponsor!.id, updatedSponsorship);
                notify('lien de parrainage modifié avec succès!');
                onClose(); 
            }
        } catch (error) {
            notifyError('Erreur lors de la modification');
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form>
                <Box display="flex" flexDirection="column" sx={{ gap: 2 }}>
                    <SearchUser name="selectedUser" initialUser={sponsor?.user} />
                    <DatePickerInput label="Start Date" name="startDate" required />
                    <DatePickerInput label="End Date" name="endDate" required />
                        <Button type="submit" variant="contained" color="primary">
                            Modifier
                        </Button>
                </Box>
            </Form>
        </Formik>
    );
};

export default EditSponsorshipForm;
