import { useQuery } from '@tanstack/react-query';
import searchRepository from "../Repositories/SearchRepository";
import userRepository from "../Repositories/UserRepository";
import { Pagination } from "../../Types/Paginator";

export const useSearchOrphan = (q: string, withOrphanage: boolean, pagination: Partial<Pagination> = {}) => {
    return useQuery({
        queryKey: ['orphan', q, withOrphanage, pagination],
        queryFn: async () => {
            const filters = { q, withOrphanage, ...pagination };
            return await searchRepository.getSearchOrphan(filters);
        }
    });
};

export const useSearchUser = (q: string, pagination: Partial<Pagination> = {}) => {
    return useQuery({
        queryKey: ['user', q, pagination],
        queryFn: async () => {
            const filters = { q, ...pagination };
            return await userRepository.getSearchUser(filters);
        },
    });
};




