import React from "react";
import {Backdrop, CircularProgress, Typography} from "@mui/material";

interface Props {
    show: boolean;
    title?: string;
}

const Loading:React.FC<Props> = ({show, title}) => {
    if (!show) {
        return null;
    }

    return <Backdrop sx={{flexDirection: "column", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
        <CircularProgress color="inherit" />
        {title && <Typography display="block" sx={{marginTop: 5}}>{title}</Typography>}
    </Backdrop>;
}

export default Loading;