import { Box } from '@mui/material';
import React from 'react';
import ImageItem from "./ImageItem";
import "./ImagePreview.scss";

interface BaseMultiple {
    images?: File[] | string[];
    isMultiple: true;
}

interface BaseItem {
    images?: File | string;
    isMultiple: false;
}

type BaseProps = BaseMultiple | BaseItem;

type Props = BaseProps & {
    className?: string;
    onDelete?: (index: number) => void;
    deletable?: boolean;
}

const ImagePreview: React.FC<Props> = ({ images, className, isMultiple, onDelete, deletable }) => {
    if (!images) {
        return null;
    }

    return (
        <Box className={`image-gallery ${!isMultiple ? 'single-image' : ''} ${className}`}>
        {isMultiple ? (
                images.map((image, index: number) => (
                    <ImageItem deletable={deletable} key={index} image={image} index={index} onDelete={onDelete}/>
                ))
            ) : (
                <ImageItem deletable={deletable} key={0} image={images} index={0} onDelete={onDelete}/>
            )}
        </Box>
    );
};

export default ImagePreview;