import {LoginToken, UserLogged} from "../Types/Admin";

export class Auth {
    static logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('until');
        localStorage.removeItem('user');
    }

    static login(token: LoginToken) {
        localStorage.setItem('token', token.token);
        localStorage.setItem('until', Number(token.until).toString());
        localStorage.setItem('user', JSON.stringify(token.user));
    }

    static getToken(): string | null {
        return localStorage.getItem('token');
    }

    static isTokenValid(): boolean {
        const until = localStorage.getItem('until');

        if (!until) {
            return false;
        }

        const now = new Date();
        const untilDate = new Date(Number(until) * 1000);

        return now < untilDate;
    }

    static getUser(): UserLogged | null {
        const jsonUser = localStorage.getItem('user');

        if (jsonUser) {
            return JSON.parse(jsonUser)! as UserLogged;
        }

        return null;
    }

    static isLogged(): boolean {
        return Auth.getToken() !== null && Auth.isTokenValid();
    }

    static hasAccess(): boolean {
        return this.isLogged();
    }
}
