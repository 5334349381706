import Orphanage from "../../../Types/Orphanage";
import {statuses} from "../../../Components/Const/Transverse";
import {Link} from "react-router-dom";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {ApiColumn} from "../../../Services/TabUtils";
import {Avatar} from "@mui/material";

export const oprhanageColumns: ApiColumn<Orphanage>[] = [
    { field: 'id', headerName: 'ID'},
    { field: 'name', headerName: 'Nom'},
    { field: 'status', headerName: 'Status', type: 'singleSelect', valueOptions: statuses},
    { field: 'position', headerName: 'Position', type: 'number'},
    { field: 'city', headerName: 'Ville', valueGetter: (city: Orphanage['city']) => city.nameFr, sortable: false, filterable: false},
    { field: 'createdAt', headerName: 'Cree le', type: 'date_api'},
    { field: 'mobileImageUrl', headerName: 'PC Image', renderCell: (params: any) => params.value && <Avatar src={params.value} alt="mobile Image" variant="square" />},
    { field: 'pcImageFileUrl', headerName: 'PC Image', renderCell: (params: any) => params.value && <Avatar src={params.value} alt="PC Image" variant="square" />},
    { field: 'actions', headerName: 'Actions', type: 'actions', getActions: (params: any) => [<Link to={`/orphanages/${params.row.id}`}>
        <EditRoundedIcon /> {'   '}
    </Link>]}
];