import React from 'react';
import Select, {SelectProps} from "../Select";
import {statuses} from "../../Const/Transverse";

interface Props extends  Omit<SelectProps, 'options'|'name'|'label'> {
    name?: string;
    label?: string;
}

const StatusesSelector: React.FC<Props> = ({name = "status", label = "Status", ...props}) => {
    return <Select name="status" label="Status" options={statuses} {...props} />
};

export default StatusesSelector;