import React from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { User } from '../../../../../Types/User';
import { Paginator } from '../../../../../Types/Paginator';
import { useSearchUser } from '../../../../../Api/Queries/SearchQuery';
import PaginatedAutocomplete from '../../../../../Components/Form/AutocompleteField';

interface SearchUserProps {
    onSelect?: (user: User) => void;
    name: string;
    initialUser?: User; 
}

const SearchUser: React.FC<SearchUserProps> = ({ onSelect, name, initialUser }) => {
    const useQuery = (searchTerm: string): UseQueryResult<Paginator<User>> => {
        return useSearchUser(searchTerm);
    };

    return (
        <PaginatedAutocomplete
            name={name}
            placeholder="Rechercher un parrain"
            query={useQuery}
            getOptionLabel={(option: User) => `${option.firstName} ${option.lastName} (${option.email})`}
            getOptionKey={(option: User) => option.id}
            onSelect={onSelect}
            initialValue={initialUser} 
        />
    );
};

export default SearchUser;
