import React from "react";
import {updateCountry, useGetByIdCountry} from '../../../Api/Queries/CountryQuery';
import Country from "./../../../Types/Country";
import {notify} from "../../../Components/Utils/Notifications";
import CountryForm from "../Form/CountryForm";
import Loading from "../../../Components/Feeback/Loading";
import {useParams} from "react-router-dom";
import {pick} from "lodash";
import {Grid, Typography} from "@mui/material";

type RouteParams = keyof {
    id?: string;
}

const CountryEditPage: React.FC = () => {
    const { id } = useParams<RouteParams>();
    const {isLoading, isSuccess, data: country} = useGetByIdCountry(id!);

    const handleSubmit = (values: Country) => {
        const country = pick(values, ['nameFr', 'nameEn', 'status', 'position']);
        updateCountry(id!, country).then(() => {
            notify('Pays mis a jour');
        });
    }

    return (
        <>
            <Loading show={isLoading} />
            <Grid container direction="column">
                <Grid item marginY={5}>
                    <Typography align="center" gutterBottom variant="h3">Editer un pays</Typography>
                </Grid>

                <Grid item>
                {
                    isSuccess && <CountryForm onSubmit={handleSubmit} defaultValues={country}/>
                }
                </Grid>
            </Grid>
        </>
    );
}

export default CountryEditPage;
