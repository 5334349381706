import React from 'react';
import {Box, Button, Container, Grid, Typography} from '@mui/material';
import { Link } from 'react-router-dom';
import ApiTable from "../../../Components/DataDisplay/ApiTable";
import {countryColumns} from "./table";
import {useGetAllCountry} from "../../../Api/Queries/CountryQuery";

const CountryListPage: React.FC = () => {
    return (
        <Box>
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Liste des pays</Typography>
            </Grid>

            <Grid container justifyContent="end" marginY={1}>
                <Grid item>
                    <Link to="/countries/create">
                        <Button type="button" variant="contained" color="primary">Ajouter un pays</Button>
                    </Link>
                </Grid>
            </Grid>

            <ApiTable query={useGetAllCountry} columns={countryColumns} />
        </Box>
    );
};

export default CountryListPage;
