import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {Modal, Grid, Button} from "@mui/material";
import {Formik, Form, FormikHelpers} from 'formik';
import {createCity} from "../../../Api/Queries/CityQuery";
import {CreateCity} from "../../../Types/City";
import {StatusEnum} from "../../../Types/Enum";
import "./CityForm.scss";
import Input from "../../../Components/Form/Input";
import StatusesSelector from "../../../Components/Form/Select/StatusesSelector";
import CountrySelector from "../../../Components/Form/Select/CountrySelector";
import {notify, notifyError} from "../../../Components/Utils/Notifications";

interface Props {
    defaultValues?: Partial<CreateCity>;
    onSubmit: (country: CreateCity) => void;
}

const CitiForm: React.FC<Props> = ({ defaultValues, onSubmit}) => {
    const initialValues: CreateCity = {
        nameFr: '',
        nameEn: '',
        position: 0,
        status: StatusEnum.DRAFT,
        country: '',
        ...defaultValues,
    };

    const handleSubmit = (values: CreateCity) => {
        onSubmit(values);
    }

    return (<Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
            <Grid container rowSpacing={3} direction="column">
                <Grid item>
                    <Input label="Nom (Fr)" name="nameFr" />
                </Grid>

                <Grid item>
                    <Input label="Nom (En)" name="nameEn" />
                </Grid>

                <Grid item>
                    <Input label="Position" name="position" type="number" />
                </Grid>

                <Grid item>
                    <StatusesSelector />
                </Grid>

                <Grid item xs={10}>
                    <CountrySelector name="country" label="Pays" />
                </Grid>

                <Grid container item justifyContent="center">
                    <Button type="submit" variant="contained">Ajouter une ville</Button>
                </Grid>
            </Grid>
        </Form>
    </Formik>);
}

export default CitiForm;
