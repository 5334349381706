import React, { useState } from 'react';
import { Box, Container, IconButton, Grid, Card, CardHeader, CardMedia, CardContent, Menu, MenuItem, ListItemIcon, Typography, Fab } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetAllVideos } from '../../../../../Api/Queries/VideoQuery';
import { useToggle } from "../../../../../Hooks/Transverse";
import DeleteVideo from '../Delete/DeleteVideo';
import Video from '../../../../../Types/Video';
import AddIcon from "@mui/icons-material/Add";
import VideoCreate from "../../Video/Create/VideoCreate";
import Orphanage from '../../../../../Types/Orphanage';
import EditVideo from '../Edit/EditVideo';
import './VideoList.scss';

interface ListVideoProps {
    orphanage: Orphanage;
}

const VideoList: React.FC<ListVideoProps> = ({ orphanage }) => {
    const { isOn: isShownVideoCreate, on: showVideoCreate, off: hideVideoCreate } = useToggle(false);
    const { isOn: isShownModalDelete, on: showModalDelete, off: hideModalDelete } = useToggle(false);
    const { isOn: isShownModalEdit, on: showModalEdit, off: hideModalEdit } = useToggle(false);
    const { data: videos, isLoading, refetch } = useGetAllVideos();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

    const handleDeleteCancel = () => {
        setSelectedVideo(null);
        hideModalDelete();
    };
    const handleEditCancel = () => {
        setSelectedVideo(null);
        hideModalEdit();
    };

    const handleDeleteClick = (video: Video) => {
        setSelectedVideo(video);
        showModalDelete();
        handleCloseMenu();
    };

    const handleEditClick = (video: Video) => {
        setSelectedVideo(video);
        showModalEdit();
        handleCloseMenu();
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, video: Video) => {
        setSelectedVideo(video);
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <Grid item>
                <h2>Liste des vidéos</h2>
            </Grid>

            <Grid item container justifyContent="end" marginBlockStart={2} marginBottom={4}>
                <Fab color="primary" size="small" variant="extended" aria-label="add" onClick={showVideoCreate}>
                    <AddIcon />
                    Ajouter une vidéo
                </Fab>
            </Grid>

            <VideoCreate open={isShownVideoCreate} onClose={hideVideoCreate} orphanage={orphanage!} onRefresh={refetch} />

            <Grid container spacing={4}>
                {videos?.results.map((video) => (
                    <Grid item xs={12} sm={6} md={4} key={video.id}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardHeader
                                action={
                                    <IconButton aria-label="settings" onClick={(event) => handleMenuClick(event, video)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title={video.titleFr}
                                subheader={video.titleEn}
                            />
                            <Box className="hoverImageBox" onClick={() => window.open(video.youtubeUrl, '_blank')}>
                                <CardMedia
                                    sx={{ width: '100%' }}
                                    component="img"
                                    height="194"
                                    image={video.pcImageFileUrl}
                                    alt={video.titleFr}
                                />
                                <YouTubeIcon className="youTubeIconStyled" fontSize="large" />
                            </Box>
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    {video.youtubeUrl}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                <MenuItem onClick={() => handleEditClick(selectedVideo!)}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    Editer
                </MenuItem>
                <MenuItem onClick={() => handleDeleteClick(selectedVideo!)}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    Supprimer
                </MenuItem>
            </Menu>
            {selectedVideo && (
                <>
                    <DeleteVideo video={selectedVideo} open={isShownModalDelete} onClose={handleDeleteCancel} onRefresh={refetch} />
                    <EditVideo video={selectedVideo} open={isShownModalEdit} onClose={handleEditCancel} onRefresh={refetch} />
                </>
            )}
        </Container>
    );
};

export default VideoList;
