import { BaseRepository } from './BaseRepository';
import Country  from '../../Types/Country';
import City from "../../Types/City";

class CountryRepository extends BaseRepository<Country> {
    protected getArea(): string {
        return 'countries';
    }
}

export default new CountryRepository();