import React from 'react';
import Select, {SelectProps} from "../Select";
import {SelectOption} from "../../../Types/Transverse";
import {useGetAllCity} from "../../../Api/Queries/CityQuery";

interface CountrySelectorProps extends  Omit<SelectProps, 'options'> {
    countryId?: string;
}

const CitySelector: React.FC<CountrySelectorProps> = ({countryId, ...props}) => {
    const { data, isLoading } = useGetAllCity(countryId);
    const options: SelectOption[] = isLoading ? [] : data!.results.map(city => ({label: city.nameFr, value: city.id as string}));

    return <Select emptyText="Aucune ville" options={options} {...props} isLoading={isLoading}/>;
};

export default CitySelector;