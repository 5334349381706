import React from "react";
import TabComponent from "../../../../Components/Form/TabComponent";
import { LocaleEnum } from "../../../../Types/Enum";
import OrphanTranslationCreate from "../../Translation/Create/OrphanTranslationCreate";
import SponsorsList from "../Sponsors/List/SponsorsList";

const OrphanTranslationTabs: React.FC = () => {
  const tabs = [
    { label: "Francais", value : "1", component: <OrphanTranslationCreate locale={LocaleEnum.FR} /> },
    { label: "Anglais", value :"2", component: <OrphanTranslationCreate locale={LocaleEnum.EN} /> },
    { label: "Parrains", value:"3", component: <SponsorsList /> }
  ];

  return (
      <TabComponent tabs={tabs} />
  );
};

export default OrphanTranslationTabs;
