import React, {useState} from 'react';
import { Formik, Form } from 'formik';
import {Button, Grid} from '@mui/material';
import * as Yup from 'yup';
import Input from '../../../../Components/Form/Input';
import {Field} from "formik";
import {UpdateOrphanage} from '../../../../Types/Orphanage';
import {notify, notifyError} from "../../../../Components/Utils/Notifications";
import {useGetOrphanageById, updateOrphanageGallery, deleteGallery} from '../../../../Api/Queries/OrphanageQuery';
import { useParams } from 'react-router-dom';
import CitySelector from '../../../../Components/Form/Select/CitySelector';
import StatusesSelector from '../../../../Components/Form/Select/StatusesSelector';
import UploadImage from "../../../../Components/Form/ImageComponents/UploadImage";
import Loading from "../../../../Components/Feeback/Loading";
import ImageItem from '../../../../Components/Form/ImageComponents/ImageItem';
import {useToggle} from "../../../../Hooks/Transverse";
import ConfirmationDialog from "../../../../Components/Layout/ConfirmationDialog";
import { Box, Paper } from '@mui/material';
import ImagePreview from "../../../../Components/Form/ImageComponents/ImagePreview";
import VideoList from '../Video/List/VideoList';
import ModalImage from '../Gallery/ModalGallery';
import './OrphanageInfoTabs.scss';

const OrphanageInfoTabs: React.FC = () => {
    const {id} = useParams<{ id?: string | undefined }>();
    const {data: orphanage, isLoading,refetch  } = useGetOrphanageById(id!);
    const {isOn: openAddGalleryModal, on: showAddGalleryModal, off: hideAddGalleryModal} = useToggle();
    const { isOn: openConfirmation, on: showConfirmation, off: hideConfirmation } = useToggle();
    const [deleteImageIndex, setDeleteImageIndex] = useState<number>(0);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Le nom est requis'),
        position: Yup.number().required('La position est requise'),
        status: Yup.string().required('Le statut est requis'),
        city: Yup.string().required('Veuaillez sélectionner une ville'),
    });

    const update = async (values: UpdateOrphanage) => {
        try {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('position', String(values.position));
            formData.append('status', values.status);
            formData.append('city', values.city);
            if (values.mobileImageFile) {
                formData.append('mobileImageFile', values.mobileImageFile);
            }
            if (values.pcImageFile) {
                formData.append('pcImageFile', values.pcImageFile);
            }

            await updateOrphanageGallery(id!, formData);
            notify('Orphelinat modifier avec succes');
            refetch();
        } catch (error) {
            notifyError('Erreur lors de la modification de l orphelinat');
            console.error('Error updating orphanage:', error);
        }
    };
    const handleDeleteImage = async () => {
        const imageId = orphanage?.images![deleteImageIndex].id;
        try {
            await deleteGallery(imageId!, orphanage!);
            notify('Image supprimée avec succès');
            hideConfirmation();
            await refetch();
        } catch (error) {
            notifyError('Erreur lors de la suppression de l\'image');
        }
    };

    const handleDeleteConfirmation = (index: number) => {
        setDeleteImageIndex(index);
        showConfirmation();
    };
    if (isLoading || !orphanage) {
        return <Loading show={isLoading} title="Chargement en cours..." />;
    }

    const initialValues: UpdateOrphanage = {
        ...orphanage!,
        city: orphanage.city.id!
    }

    return (
        <Grid container spacing={2} rowSpacing={5} direction="column">
            <Grid item>
                <h2>Modifier un projet</h2>
            </Grid>

            <Formik initialValues={initialValues} onSubmit={update} validationSchema={validationSchema}>
                <Form>
                    <Grid container spacing={2} rowSpacing={5} direction="column">
                        <Grid item>
                            <Input name="name" label="Nom de l'orphelinat" formControlProps={{fullWidth: true}}/>
                        </Grid>
                        <Grid item>
                            <Input name="position" type="number" label="Position" formControlProps={{fullWidth: true}}/>
                        </Grid>
                        <Grid item>
                            <StatusesSelector/>
                        </Grid>
                        <Grid item>
                            <Field name="city" as={CitySelector} label="Ville de l'orphelinat"  />
                        </Grid>
                        <Grid item>
                            <UploadImage name="mobileImageFile"  label="Ajouter une image (mobile)" showPreview={true} isMultiple={false} className="UploadImage" />
                            <ImagePreview isMultiple={false} images={orphanage.mobileImageUrl}  />
                        </Grid>
                        <Grid item>
                            <UploadImage name="pcImageFile" label="Ajouter une image (ordinateur)" showPreview={true} isMultiple={false} className="UploadImage" />
                            <ImagePreview isMultiple={false} images={orphanage.pcImageFileUrl}  />
                        </Grid>

                        <Grid item>
                            <Grid item marginY={2}>
                                <Button type="button" variant="contained" onClick={showAddGalleryModal}>Ajouter une image</Button>
                            </Grid>

                            <div className='image-preview'>
                                {
                                    orphanage?.images!.map((image, index) => (
                                        <ImageItem deletable key={index} image={image.imageFile} index={index} onDelete={() => handleDeleteConfirmation(index)} />
                                    ))
                                }
                            </div>
                        </Grid>
                        <Grid item>
                            <div className='btn'>
                                <Button type="submit" variant="contained">Modifier</Button>
                            </div>
                        </Grid>
                        <Grid item>
                            <Paper elevation={3}>
                                <Box p={2}>
                                    <VideoList orphanage={orphanage!}/>
                                </Box>
                            </Paper>
                        </Grid>

                    </Grid>
                </Form>
            </Formik>
            <ConfirmationDialog
                open={openConfirmation}
                title="Confirmation"
                message="Voulez-vous vraiment supprimer cette image ?"
                onConfirm={() => {
                    hideConfirmation();
                    handleDeleteImage();
                }}
                onCancel={hideConfirmation}
            />
            <ModalImage onClose={hideAddGalleryModal} orphanage={orphanage} open={openAddGalleryModal} onAdd={hideAddGalleryModal} />
        </Grid>
    );
};

export default OrphanageInfoTabs;