import { Grid, Typography } from "@mui/material";
import React from "react";
import FaqForm from "../Form/FaqForm";
import { createFaq } from "../../../Api/Queries/FaqQuery";
import Faq from "../../../Types/Faq";
import { notify, notifyError } from "../../../Components/Utils/Notifications";
import { useNavigate } from "react-router-dom";

const FaqCreate: React.FC = () => {
    const navigate = useNavigate();
    
    const handleSubmit =  async (values: Faq) => {
        try {
            const faq = await createFaq({...values});
            notify("FAQ créée avec succès");
            navigate("/faqs");
        } catch (error) {
            notifyError("Erreur lors de la création de la FAQ");
        }
    };

    return (
    <Grid container direction="column">
        <Grid item marginY={5}>
            <Typography align="center" gutterBottom variant="h3">Ajouter un nouveau F.A.Q</Typography>
        </Grid>

        <Grid item>
           <FaqForm onSubmit={handleSubmit} /> 
        </Grid>
    </Grid>
    );
}
export default FaqCreate