import { useQuery } from "@tanstack/react-query";
import { Pagination } from "../../Types/Paginator";
import VideoRepository from '../Repositories/VideoRepository';

export const useGetAllVideos = (pagination: Partial<Pagination> = {}) => {
    return useQuery({
        queryKey: ['videos', pagination],
        queryFn: async () => {
            return await VideoRepository.getAll(pagination);
        }
    });
};

export const useGetVideoById = (id: string) => {
    return useQuery({
        queryKey: ['video', id],
        queryFn: async () => {
            return await VideoRepository.getById(id);
        }
    });
};

export const createVideo = async (video: FormData) => {
    return await VideoRepository.createWithFormData(video);
};


export const updateVideo = async (id: string, update: FormData) => {
    return await VideoRepository.updateWithFormData(id, update);
};

export const deleteVideo = (id: string ) => {
    return VideoRepository.delete(id);
};