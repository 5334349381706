import { Navigate, useLocation } from 'react-router-dom';
import {Auth} from "../../Services/Auth";

const Guard = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();

    if (!Auth.hasAccess()) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export default Guard;
