import React from 'react';

interface HtmlCellProps {
    value: string;
}

const HtmlCell: React.FC<HtmlCellProps> = ({ value }) => {
    return <div dangerouslySetInnerHTML={{ __html: value }} />;
};

export default HtmlCell;
