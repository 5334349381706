import React, {useMemo} from 'react';
import { useField } from 'formik';
import {FormControl, FormHelperText, FormControlProps, Fab, Box} from '@mui/material';
import { FC } from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ImagePreview from "./ImagePreview";
import {uniqueId} from "lodash";

interface ImageUploadProps {
    name: string;
    label: string;
    formControlProps?: Partial<FormControlProps>;
    isMultiple: boolean;
    className?:string;
    showPreview?: boolean;
}

const UploadImage: FC<ImageUploadProps> = ({ name, label, formControlProps, isMultiple, showPreview, className }) => {
    const [field, meta, helper] = useField(name);
    const hasError = meta.touched && meta.error !== undefined;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
            if (isMultiple) {
                const newImages = Array.from(event.currentTarget.files);
                helper.setValue([...(field.value ?? []), ...newImages]);
            } else {
                helper.setValue(event.currentTarget.files[0]);
            }
        }
    };

    const handleDeleteImage = (index: number) => {
        if (isMultiple) {
            const newImages = [...field.value];
            newImages.splice(index, 1);
            helper.setValue(newImages);
        } else {
            helper.setValue(undefined);
        }
    };

    const id = useMemo(() => {
        return uniqueId(name);
    }, [name])

    return (
        <FormControl fullWidth error={hasError} {...formControlProps}>
            <label htmlFor={id}>
                <Fab color="primary" size="small" variant="extended" component="span">
                    <AddPhotoAlternateIcon />
                    {label}
                </Fab>
            </label>
            <Box display="none">
                <input id={id} type="file" onChange={handleFileChange} accept="image/*" className={className} multiple={isMultiple}/>
            </Box>
            {
                showPreview && field.value && (
                    <ImagePreview deletable images={field.value} className={className} isMultiple={isMultiple} onDelete={handleDeleteImage} />
                )
            }
            {hasError && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};
export default UploadImage;