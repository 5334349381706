import React from "react";
import {ApiColumn} from "../../../Services/TabUtils";
import {educationLevels, genders, statuses} from "../../../Components/Const/Transverse";
import { Link } from "react-router-dom";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import {Orphan} from "../../../Types/Orphan";
import {Avatar} from "@mui/material";

export const orphanColumns: ApiColumn<Orphan>[] = [
    { field: 'id', headerName: 'ID'},
    { field: 'firstName', headerName: 'Nom '},
    { field: 'lastName', headerName: 'Prenom'},
    { field: 'gender', headerName: 'Genre', type: 'singleSelect', valueOptions: genders},
    { field: 'status', headerName: 'Status', type: 'singleSelect', valueOptions: statuses},
    { field: 'position', headerName: 'Position', type: 'number'},
    { field: 'birthDate', headerName: 'date de naissance', type: 'date_api'},
    { field: 'educationLevel', headerName: "Niveau d'etude",type: 'singleSelect', valueOptions: educationLevels},
    { field: 'photoFileUrl', headerName: 'Photo', renderCell: (params: any) => params.value && <Avatar src={params.value} alt="Photo" variant="square" />},
    { field: 'orphanage', headerName: 'Orphanage', valueGetter: (orphanage: Orphan['orphanage']) => orphanage?.name},
    { field: 'createdAt', headerName: 'Cree le', type: 'date_api'},
    { field: 'actions', headerName: 'Actions', type: 'actions', getActions: (params: any) => [<Link to={`/orphan/${params.row.id}`}>
            <EditRoundedIcon /> {'   '}
        </Link>]}
];