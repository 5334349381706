import {Box, IconButton} from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import "./ImageItem.scss";

interface ImageItemProps {
    image: File | string | undefined;
    index: number;
    onDelete?: (index: number) => void;
    deletable?: boolean;
}

const ImageItem: React.FC<ImageItemProps> = ({ image, index, deletable = false, onDelete }) => {
    if (!image) {
        return null;
    }

    const handleDelete = () => {
        onDelete && onDelete(index);
    };

    const src = typeof image === 'string' ? image : URL.createObjectURL(image);

    return (
        <Box className="image-item">
            <img src={src} alt={`Aperçu ${index}`} />
            {deletable && (
                <IconButton onClick={handleDelete} className="delete-icon">
                    <DeleteIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default ImageItem;