import React from 'react';
import {Box, Button, Grid, Typography} from '@mui/material';
import { Link } from 'react-router-dom';
import ApiTable from "../../../Components/DataDisplay/ApiTable";
import { useGetAllCityPaginated } from "../../../Api/Queries/CityQuery";
import {cityColumns} from "./table";

const CityListPage: React.FC = () => {
    return (
        <Box>
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Liste des villes</Typography>
            </Grid>

            <Grid container justifyContent="end" marginY={1}>
                <Grid item>
                    <Link to="/cities/create">
                        <Button type="button" variant="contained" color="primary">Ajouter une ville</Button>
                    </Link>
                </Grid>
            </Grid>

            <ApiTable query={useGetAllCityPaginated} columns={cityColumns} />
        </Box>
    );
};

export default CityListPage;
