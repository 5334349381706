import React from "react";
import {Dialog, DialogActions, Button, DialogTitle, DialogContent} from "@mui/material";

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    confirmText?: string;
    cancelText?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({open, title, message, onConfirm, onCancel, cancelText = "Annuler", confirmText = "Confirmer"}) => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>{message}</DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="error" variant="contained">
                    {cancelText}
                </Button>
                <Button onClick={onConfirm} color="primary" variant="contained" autoFocus>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default ConfirmationDialog;
