import { Grid, Typography } from "@mui/material";
import React from "react";
import { notify,notifyError } from "../../../../Components/Utils/Notifications";
import { OrphanTranslation } from "../../../../Types/Orphan";
import OrphanTranslationForm from "../Form/OrphanTranslationForm";
import { createTranslation, useGetOrphanById } from "../../../../Api/Queries/OrphanQuery";
import { useParams } from 'react-router-dom'; 
import { LocaleEnum } from "../../../../Types/Enum";
import Loading from "../../../../Components/Feeback/Loading";

interface Props{
    locale:LocaleEnum;
}

const OrphanTranslationCreate: React.FC<Props> = ({locale}) => {
    const {id} = useParams<{ id?: string}>();
    const { data: orphan, isLoading } = useGetOrphanById(id!);

    const handleSubmit =  async (values: OrphanTranslation) => {
        try {
            await createTranslation(id!, values);
            notify("Translation créée avec succès");
        } catch (error) {
            notifyError("Erreur lors de la création de la traduction");
        }
    };

    if (isLoading) {
        return <Loading show={isLoading} title="Chargement en cours..." />;
    }

    const existingTranslation = orphan?.translations?.find(translation => translation.locale === locale);
    
    return (
    <Grid container direction="column">
        <Grid item marginY={5}>
            <Typography align="center" gutterBottom variant="h3">Ajouter la traduction</Typography>
        </Grid>

        <Grid item>
           <OrphanTranslationForm onSubmit={handleSubmit} locale={locale} defaultValues={existingTranslation}/> 
        </Grid>
    </Grid>
    );
}
export default OrphanTranslationCreate;