import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { Box } from '@mui/material';
import { useGetStatisticsOrphanages } from '../../../Api/Queries/StatisticQuery';
import Loading from '../../../Components/Feeback/Loading';

const OrphanagesStatistic: React.FC = () => {

  const {data, isLoading} = useGetStatisticsOrphanages();
  
  if (isLoading) {
    return <Loading show={isLoading} title="Chargement en cours..." />;
  }

  return (
    <Box sx={{ marginTop: 5, boxShadow: 3, padding: 2, height: 300 }}>
      <h2>Nombre d'Orphelinats</h2>
      <PieChart
        colors={['#FFCF96', '#ECB176', '#F8C794']}
        series={[
          {
            data: [
              { id: 0, value: data.published, label: 'Publié' },
              { id: 1, value: data.unpublished, label: 'Non publié' },
              { id: 2, value: data.draft, label: 'Brouillon' },
            ],
          },
        ]}
        width={400}
        height={200}
      />
    </Box>
  );
};

export default OrphanagesStatistic;
