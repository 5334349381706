import React from 'react';
import { useField } from 'formik';
import {InputLabel, Input as InputMaterial, FormControl, FormHelperText, FormControlProps} from '@mui/material';
import { FC } from 'react';

interface InputProps {
    label: string;
    name: string;
    type?: 'text' | 'number' | 'email' | 'password';
    placeholder?: string;
    formControlProps?: Partial<FormControlProps>;
    disabled?:boolean;
}

const Input: FC<InputProps> = ({ type = 'text', label, name, placeholder, formControlProps, disabled }) => {
    const [field, meta] = useField(name);
    const hasError = meta.touched && meta.error !== undefined;

    return (
        <FormControl fullWidth error={hasError} {...formControlProps}>
            <InputLabel>{label}</InputLabel>
            <InputMaterial {...field} type={type} placeholder={placeholder} disabled={disabled} />
            {hasError && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default Input;
