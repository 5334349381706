import Country from "./../../../Types/Country";
import React from "react";
import Dialog from "../../../Components/Layout/Dialog";
import CountryCreate from "./CountryCreate";

interface Props {
    open: boolean;
    onClose: () => void;
    onCreate?: (country: Country) => void;
}

const ModalCountryCreate: React.FC<Props> = ({ open, onClose, onCreate }) => {
    return (
        <Dialog open={open} title="Ajouter un pays" onClose={onClose}>
            <CountryCreate onCreate={onCreate} />
        </Dialog>
    );
}

export default ModalCountryCreate;
