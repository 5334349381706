import { ApiColumn } from "../../../../../Services/TabUtils";
import { Sponsorship } from "../../../../../Types/Sponsorship";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteIcon from '@mui/icons-material/Delete';

interface SponsorshipColumnsProps {
    onDeleteClick: (sponsorship: Sponsorship) => void;
    onEditClick: (sponsorship: Sponsorship) => void;
}

export const SponsorshipColumns = ({ onDeleteClick, onEditClick }: SponsorshipColumnsProps): ApiColumn<Sponsorship>[] => [

    { field: 'id', headerName: 'ID' },
    { 
        field: 'user', headerName: 'Nom du parrain ', 
        valueGetter: (user: Sponsorship['user']) => {
            return user ? `${user.firstName} ${user.lastName} ${user.email}` : '';
        },
    },
    { field: 'startDate', headerName: 'Date début', type: 'date_api' },
    { field: 'endDate', headerName: 'Date fin', type: 'date_api' },
    { field: 'actions', headerName: 'Actions', type: 'actions', getActions: (params: any) =>
            [
                <EditRoundedIcon onClick={() => onEditClick(params.row)} />,
                <DeleteIcon onClick={() => onDeleteClick(params.row)} />
            ]
    }
    
];