import React from "react";
import EditOrphanage from './OrphanageTabs/OrphanageInfoTabs';
import OrphanListOrphanage from "../../Orphan/List/OrphanListOrphanageTabs";
import OrphanageTranslationTabs from "./OrphanageTabs/OrphanageTranslationTabs";
import TabComponents from "../../../Components/Form/TabComponent";
import OrphanageInfoTabs from "./OrphanageTabs/OrphanageInfoTabs";

const OrphanageEdit: React.FC = () => {
  const tabs = [
    { label: 'Orphelinats', value: '1', component: <OrphanageInfoTabs /> },
    { label: 'Orphelins', value: '2', component: <OrphanListOrphanage /> },
    { label: 'Traduction', value: '3', component: <OrphanageTranslationTabs /> },
  ];

  return <TabComponents tabs={tabs} />;
};

export default OrphanageEdit;
