import { BaseRepository } from './BaseRepository';
import {Orphan,CreateOrphan, OrphanTranslation} from '../../Types/Orphan';

class OrphanRepository extends BaseRepository<Orphan, CreateOrphan> {
    protected getArea(): string {
        return 'orphans';
    }

    async updateOrphan(id: string, formData: FormData): Promise<Orphan> {
        const url = `${this.getArea()}/update/${id}`;
        const response = await this.client.post(url, { body: formData});

        return response.json();
    }

    async createTranslation(id:string, data: OrphanTranslation): Promise<OrphanTranslation> {
        const response = await this.client.post(`${this.getArea()}/${id}/translations`, {
            json: data,
        });

        return response.json();
    }
}
export default new OrphanRepository();