import React from 'react';
import {Box, Button, Container, Grid, Typography} from '@mui/material';
import { Link } from 'react-router-dom';
import ApiTable from "../../../Components/DataDisplay/ApiTable";
import { orphanColumns} from "./Table";
import {useGetAllOrphans} from "../../../Api/Queries/OrphanQuery";

const OrphanListPage: React.FC = () => {
    return (
        <Box>
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Liste des orphelins</Typography>
            </Grid>

            <Grid container justifyContent="end" marginY={1}>
                <Grid item>
                    <Link to="/orphan/create">
                        <Button type="button" variant="contained" color="primary">Ajouter un orphan</Button>
                    </Link>
                </Grid>
            </Grid>

            <ApiTable query={useGetAllOrphans} columns={orphanColumns} />
        </Box>
    );
};

export default OrphanListPage;
