import { ApiColumn } from "../../../Services/TabUtils";
import Contact from "../../../Types/Contact";
import {subjects} from "../../../Components/Const/Transverse";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface ContactColumnsProps {
    onDeleteClick: (contact: Contact) => void;
    onViewClick: (contact: Contact) => void;
}

export const contactColumns = ({ onDeleteClick, onViewClick  }: ContactColumnsProps): ApiColumn<Contact>[] => [
    { field: 'id', headerName: 'ID'},
    { field: 'fullName', headerName: 'Nom complet'},
    { field: 'phone', headerName: 'Téléphone'},
    { field: 'email', headerName: 'Email'},
    { field: 'subject', headerName: 'Sujet', type: 'singleSelect', valueOptions: subjects},
    { field: 'message', headerName: 'Message'},
    { field: 'createdAt', headerName: 'Créé le', type: 'date_api'},
    {field: 'actions', headerName: 'Actions', type: 'actions', getActions: (params: any) =>
        [ 
            <VisibilityIcon onClick={() => onViewClick(params.row)} />,
            <DeleteIcon onClick={() => onDeleteClick(params.row)} />
        ]
    },
];