import { BaseRepository } from './BaseRepository';
import {Admin, CreateAdmin} from "../../Types/Admin";

class AdminRepository extends BaseRepository<Admin ,CreateAdmin> {
    protected getArea(): string {
        return 'api/admins';
    }

    getMe() {
        return this.client.get(`${this.getArea()}/me`).json<Admin>();
    }
}

export default new AdminRepository();