import { Grid, Typography } from "@mui/material";
import React from "react";
import FaqForm from "../Form/FaqForm";
import Faq from "../../../Types/Faq";
import { useNavigate, useParams } from "react-router-dom";
import { updateFaq, useGetByIdFaq } from "../../../Api/Queries/FaqQuery";
import Loading from "../../../Components/Feeback/Loading";
import {pick} from "lodash";
import { notify, notifyError } from "../../../Components/Utils/Notifications";

const FaqEdit: React.FC = () => {
    const {id} = useParams<{ id?: string | undefined }>();
    const {isLoading, isSuccess, data: faq} = useGetByIdFaq(id!);
    const navigate = useNavigate();

    function handleSubmit(values: Faq): void {
        try {
            const faq =pick(values, ['locale' , 'status', 'position', 'question' , 'response']);
            updateFaq(id!, faq);
            notify('FAQ mise à jour avec succès');
            navigate("/faqs");
        } catch (error) {
            notifyError("Erreur lors de la mise à jour de la FAQ");
        }
    }

    const defaultValue: Partial<Faq> = {
        ...faq,
    }

    return(
        <>
            <Loading show={isLoading} />   
            <Grid container direction="column">
                <Grid item marginY={5}>
                     <Typography align="center" gutterBottom variant="h3">Editer un F.A.Q</Typography>
                </Grid>

                <Grid item>
                    {
                        isSuccess && <FaqForm onSubmit={handleSubmit} defaultValues={defaultValue}/> 
                    }
                </Grid>
            </Grid>
        </>
    );
}
export default FaqEdit;