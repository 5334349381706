import React from "react";
import OrphanageTranslationForm from "../Form/OrphanageTranslationForm";
import { useNavigate, useParams } from "react-router-dom";
import { OrphanageTranslation } from "../../../../../Types/OrphanageTranslation";
import { notify, notifyError } from "../../../../../Components/Utils/Notifications";
import { addTranslationToOrphanage } from "../../../../../Api/Queries/OrphanageQuery";
import { LocaleEnum } from "../../../../../Types/Enum";
import { useGetOrphanageById } from '../../../../../Api/Queries/OrphanageQuery';
import Loading from "../../../../../Components/Feeback/Loading";

interface Props {
    locale: LocaleEnum;
}

const OrphanageTranslationCreate: React.FC<Props> = ({ locale }) => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>(); 
    const { data: orphanage, isLoading } = useGetOrphanageById(id!);

    const handleSubmit = async (values: OrphanageTranslation) => {
        try {
            await addTranslationToOrphanage(id!, values); 
            notify("Traduction d'orphelinat créée avec succès");
            navigate(`/orphanages`); 
        } catch (error) {
            notifyError("Erreur lors de la soumission de la traduction d'orphelinat");
        }
    };

    if (isLoading) {
        return <Loading show={isLoading} title="Chargement en cours..." />;
    }

    const existingTranslation = orphanage?.translations?.find(translation => translation.locale === locale);

    return (

        <OrphanageTranslationForm onSubmit={handleSubmit} defaultLocale={locale} defaultValues={existingTranslation}/>
    );
};

export default OrphanageTranslationCreate;
