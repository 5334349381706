import React from "react";
import { Admin } from "../../../Types/Admin";
import ConfirmationDialog from "../../../Components/Layout/ConfirmationDialog";
import { notify, notifyError } from "../../../Components/Utils/Notifications";
import { deleteAdmin } from "../../../Api/Queries/AdminQuery";

interface Props {
    admin: Admin;
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteAdmin: React.FC<Props> = ({ admin, open, onClose, onDelete }) => {
    const handleDelete = async () => {
        try {
            await deleteAdmin(admin.id);
            onClose();
            onDelete();
            notify('Admin supprimé avec succès');
        } catch (error) {
            notifyError('Erreur lors de la suppression de l\'admin');
        }
    };
    
    return (
        <ConfirmationDialog
            open={open}
            onCancel={onClose}
            title="Supprimer l'admin"
            onConfirm={handleDelete}
            message="Êtes-vous sûr de vouloir supprimer cet admin ?"
        />
    );
};

export default DeleteAdmin;
