import { BaseRepository } from './BaseRepository';
import Orphanage, {CreateOrphanage} from '../../Types/Orphanage';
import { OrphanageTranslation } from '../../Types/OrphanageTranslation';

class OrphanageRepository extends BaseRepository<Orphanage, CreateOrphanage> {
    protected getArea(): string {
        return 'orphanages';
    }

    async updateGallery(id: string, formData: FormData): Promise<Orphanage> {
        const url = `${this.getArea()}/update/${id}`;
        const response = await this.client.post(url, { body: formData });

        return response.json();
    }

    async addGallery(id: string, formData: FormData): Promise<Orphanage> {
        const url = `${this.getArea()}/${id}/gallery`;
        const response = await this.client.post(url, { body: formData });

        return response.json();
    }

    async deleteGallery(id: string, orphanage: Orphanage) {
        const url = `${this.getArea()}/${orphanage.id}/gallery/${id}`;
        const response = await this.client.delete(url);

        return response.json();
    }

    async assignOrphanToOrphanage(orphanId: string, orphanage: string): Promise<Orphanage> {
        const url = `${this.getArea()}/${orphanage}/orphan`;
        const response = await this.client.post(url, {
            json: { orphan: orphanId },
        });
        return response.json();
    }

    async addTranslation(id: string, data: OrphanageTranslation): Promise<Orphanage> {
        const url = `${this.getArea()}/${id}/translations`;
        const response = await this.client.post(url, { json: data });

        return response.json();
    }
    
}

export default new OrphanageRepository();
