import {useState} from "react";

export const useToggle = (defaultValue: boolean = false) => {
    const [isOn, setOn] = useState<boolean>(defaultValue);

    const toggle = () => {
        setOn(!isOn);
    }

    const on = () => {
        setOn(true);
    }

    const off = () => {
        setOn(false);
    }

    return {isOn, isOff: !isOn, on, off, toggle, setOn: setOn};
}