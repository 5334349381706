import React from "react";
import {ApiColumn} from "../../../Services/TabUtils";
import Country from "../../../Types/Country";
import {statuses} from "../../../Components/Const/Transverse";
import { Link } from "react-router-dom";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

export const countryColumns: ApiColumn<Country>[] = [
    { field: 'id', headerName: 'ID'},
    { field: 'nameFr', headerName: 'Nom (fr)'},
    { field: 'nameEn', headerName: 'Nom (en)'},
    { field: 'status', headerName: 'Status', type: 'singleSelect', valueOptions: statuses},
    { field: 'position', headerName: 'Position', type: 'number'},
    { field: 'createdAt', headerName: 'Cree le', type: 'date_api'},
    { field: 'actions', headerName: 'Actions', type: 'actions', getActions: (params: any) => [<Link to={`/countries/edit/${params.row.id}`}>
        <EditRoundedIcon /> {'   '}
    </Link>]}
];