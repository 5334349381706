import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { Grid, Button } from "@mui/material";
import * as Yup from "yup";
import Orphanage from '../../../../../Types/Orphanage';
import { CreateVideo } from '../../../../../Types/Video';
import { createVideo } from '../../../../../Api/Queries/VideoQuery';
import Dialog from '../../../../../Components/Layout/Dialog';
import Input from '../../../../../Components/Form/Input';
import UploadImage from '../../../../../Components/Form/ImageComponents/UploadImage';
import { notify, notifyError } from '../../../../../Components/Utils/Notifications';

interface AddVideoModalProps {
    open: boolean;
    onClose: () => void;
    orphanage: Orphanage;
    onRefresh: () => void;
}

const VideoCreate: React.FC<AddVideoModalProps> = ({ open, onClose, orphanage,onRefresh}) => {
    const initialValues: CreateVideo = {
        youtubeUrl: '',
        titleFr: '',
        titleEn: '',
        mobileImageFile: undefined,
        pcImageFile: undefined,
        orphanage: ''
    };

    const validationSchema = Yup.object().shape({
        youtubeUrl: Yup.string().required('Lien YouTube requis'),
        titleFr: Yup.string().required('Titre (FR) requis'),
        titleEn: Yup.string().required('Titre (EN) requis'),
    });

    const handleSubmit = async (values: CreateVideo, actions: FormikHelpers<CreateVideo>) => {
        try {
            const formData = new FormData();
            formData.append('youtubeUrl', values.youtubeUrl);
            formData.append('titleFr', values.titleFr);
            formData.append('titleEn', values.titleEn);
            formData.append('mobileImageFile', values.mobileImageFile ||'');
            formData.append('pcImageFile', values.pcImageFile||'');
            formData.append('orphanage', orphanage.id);

            await createVideo(formData);
            actions.resetForm();
            onClose();
            onRefresh(); 
            notify("Vidéo ajoutée avec succès");
        } catch (error) {
            notifyError("Erreur lors de l'ajout de la vidéo");
        }
    };

    return (
        <Dialog open={open} onClose={onClose} title="Ajouter une vidéo" showClose={true}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                <Form encType="multipart/form-data">
                    <Grid container spacing={2} rowSpacing={4} direction="column">
                        <Grid item>
                            <Input name="youtubeUrl" type="text" label="URL YouTube" />
                        </Grid>
                        <Grid item>
                            <Input name="titleFr" type="text" label="Titre (FR)" />
                        </Grid>
                        <Grid item>
                            <Input name="titleEn" type="text" label="Titre (EN)" />
                        </Grid>
                        <Grid item>
                            <UploadImage name="mobileImageFile" label="Importer Image Mobile" isMultiple={false} showPreview />
                        </Grid>
                        <Grid item xs={10}>
                            <UploadImage name="pcImageFile" label="Importer Image PC" isMultiple={false} showPreview />
                        </Grid>
                        <Grid item xs={10}>
                            <Button type="submit" variant="contained">Ajouter vidéo</Button>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </Dialog>
    );
}

export default VideoCreate;
