import {Box} from "@mui/material";
import {BoxProps} from "@mui/material/Box/Box";
import {BoxOwnProps} from "@mui/system/Box/Box";

export const BlueBox: React.FC<BoxProps> = (props) => {
    const style: BoxOwnProps['sx'] = {
        borderRadius: 5,
        bgcolor: 'white',
        boxShadow: 2,
        padding: 5,
    };

    return <Box sx={style} {...props}/>
}