import React from 'react';
import { Orphan } from '../../../../../Types/Orphan';
import { Paginator } from '../../../../../Types/Paginator';
import PaginatedAutocomplete from '../../../../../Components/Form/AutocompleteField';
import { useSearchOrphan } from '../../../../../Api/Queries/SearchQuery';
import { UseQueryResult } from '@tanstack/react-query';

interface SearchOrphanProps {
    onSelect?: (orphan: Orphan) => void;
    name: string;
}

const SearchOrphan: React.FC<SearchOrphanProps> = ({ onSelect, name }) => {
    const useQuery = (searchTerm: string): UseQueryResult<Paginator<Orphan>> => {
        return useSearchOrphan(searchTerm, false);
    };

    return (
        <PaginatedAutocomplete
            name={name}
            placeholder="Rechercher"
            query={useQuery}
            getOptionLabel={(option: Orphan) => `${option.firstName} ${option.lastName}`}
            getOptionKey={(option: Orphan) => option.id}
            onSelect={onSelect}
        />
    );
};

export default SearchOrphan;
