import {Formik, Form} from 'formik';
import {Grid, Button} from "@mui/material";
import Country from "../../../Types/Country";
import {StatusEnum} from "../../../Types/Enum";
import React from "react";
import Input from "../../../Components/Form/Input";
import StatusesSelector from "../../../Components/Form/Select/StatusesSelector";
import * as Yup from "yup";

interface Props {
    defaultValues?: Partial<Country>;
    onSubmit: (country: Country) => void;
}

const CountryForm: React.FC<Props> = ({ defaultValues, onSubmit }) => {
    const initialValues: Country = {
        nameFr: '',
        nameEn: '',
        position: 0,
        status: StatusEnum.DRAFT,
        ...defaultValues
    };

    const validationSchema = Yup.object().shape({
        nameFr: Yup.string().required('Le nom francais est requis'),
        nameEn: Yup.string().required('Le nom anglais est requis'),
        position: Yup.number().required('La position est requise'),
        status: Yup.string().required('Le status doit etre renseigne'),
    });

    const handleSubmit =  async (values: Country) => {
        onSubmit(values);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            <Form>
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <Input label="Nom (fr)" name="nameFr" />
                    </Grid>
                    <Grid item>
                        <Input label="Nom (en)" name="nameEn" />
                    </Grid>
                    <Grid item>
                        <Input label="Position" name="position" type="number" />
                    </Grid>
                    <Grid item>
                        <StatusesSelector />
                    </Grid>
                    <Grid item container justifyContent="center">
                        <Button type="submit" variant="contained">Valider</Button>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
}

export default CountryForm;
