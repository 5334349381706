import { BaseRepository } from './BaseRepository';

class StatisticRepository extends BaseRepository<any> {
    protected getArea(): string {
        return 'statistics';
    }

    async getStatisticsOrphanages(): Promise<any> {
        const response = await this.client.get(`${this.getArea()}/orphanage`);
        return response.json();
    }

    async getStatisticsOrphans(): Promise<any> {
        const response = await this.client.get(`${this.getArea()}/orphan`);
        return response.json();
    }
}

export default new StatisticRepository();
