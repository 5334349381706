import React from 'react';
import { Formik, Form } from 'formik';
import { Orphan } from '../../../../../Types/Orphan';
import { assignOrphanToOrphanage } from '../../../../../Api/Queries/OrphanageQuery';
import { Box, Button } from '@mui/material';
import { notify, notifyError } from "../../../../../Components/Utils/Notifications";
import SearchOrphan from "../Search/SearchOrphan";

interface AssignOrphanProps {
    orphanage: string;
}

const OrphanAssignmentPage: React.FC<AssignOrphanProps> = ({ orphanage }) => {
    const initialValues = { selectedOrphan: null };

    const handleSubmit = async (values: { selectedOrphan: Orphan | null }) => {
        try {
            if (values.selectedOrphan) {
                await assignOrphanToOrphanage(values.selectedOrphan.id!, orphanage);
                notify('Assign orphan successfully');
            }
        } catch (error) {
            notifyError('Failed to assign orphan');
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} >
                <Form>
                    <Box display="flex" alignItems="center" sx={{ gap: 2 }}>
                        <SearchOrphan name="selectedOrphan"/>
                        <Button type="submit" variant="contained">
                            Valider
                        </Button>
                    </Box>
                </Form>
        </Formik>
    );
};

export default OrphanAssignmentPage;
