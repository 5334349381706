import React from "react";
import TabComponents from "../../../../Components/Form/TabComponent";
import EditOrphanFile from "../EditOrphan";
import OrphanTranslationTabs from "./OrphanTranslationTabs";
import SponsorsList from "../Sponsors/List/SponsorsList";

const OrphanTabs: React.FC = () => {
  const tabs = [
    { label: "Orphelins", value :"1", component: <EditOrphanFile  /> },
    { label: "Traduction", value:"2", component: <OrphanTranslationTabs /> },
    { label: "Parrains", value:"3", component: <SponsorsList /> }
  ];

  return (
      <TabComponents tabs={tabs} />
  );
};

export default OrphanTabs;
