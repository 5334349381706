import React from 'react';
import { useGetAllCountry } from '../../../Api/Queries/CountryQuery';
import Select, {SelectProps} from "../Select";
import {SelectOption} from "../../../Types/Transverse";

interface CountrySelectorProps extends Omit<SelectProps, 'options'>{
    name: string;
    label: string;
    onSelect?: (countryId: string) => void;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({label, name, onSelect, ...props}) => {
    const { data, isLoading } = useGetAllCountry();
    const options: SelectOption[] = isLoading ? [] : data!.results!.map(country => ({label: country.nameFr, value: country.id as string}));

    return <Select emptyText="Aucun pays" name={name} label={label} options={options} onSelect={onSelect} {...props} isLoading={isLoading} />;
};

export default CountrySelector;
