import React from 'react';
import AddSponsorForm from './AddSponsorForm';
import Dialog from '../../../../../Components/Layout/Dialog';

interface Props { 
    open: boolean;
    onClose: () => void;
    orphan: string;
}

const ModalSponsor: React.FC<Props> = ({ open, onClose, orphan }) => {
    return (
        <Dialog open={open} title="Ajouter un parrain" onClose={onClose}>
            <AddSponsorForm open={open} onClose={onClose} orphan={orphan} />
        </Dialog>
    );
};

export default ModalSponsor;
