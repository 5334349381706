import React from "react";
import {Grid, Typography} from "@mui/material";
import CountryCreate from "./CountryCreate";

const CountryCreatePage: React.FC = () => {
    return <Grid container direction="column">
        <Grid item marginY={5}>
            <Typography align="center" gutterBottom variant="h3">Ajouter un nouveau pays</Typography>
        </Grid>

        <Grid item>
            <CountryCreate />
        </Grid>
    </Grid>;
}

export default CountryCreatePage;
