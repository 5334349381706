import ky, {KyInstance, Options} from 'ky';
import qs from 'qs';
import {Paginator} from "../../Types/Paginator";

export abstract class BaseRepository<T, CreateDocument = any> {
    protected client: KyInstance;
    constructor() {
        this.client = ky.create({
            prefixUrl: process.env.REACT_APP_API_BASE_URL as string,
            throwHttpErrors: true,
            timeout: 1000000,
        });
    }

    protected abstract getArea(): string;

    async getAll(filters: any = {}, otherConfig: Options = {}): Promise<Paginator<T>> {
        const response = await this.client.get(`${this.getArea()}/`, { searchParams: qs.stringify(filters, { encode: false }), ...otherConfig });

        return response.json();
    }

    async getById(id: string ): Promise<T> {
        const response = await this.client.get(`${this.getArea()}/${id}`);

        return response.json();
    }

    async create(data: Partial<T>|CreateDocument): Promise<T> {
        const response = await this.client.post(`${this.getArea()}/`, {
            json: data,
        });

        return response.json();
    }

    async createWithFormData(data: FormData): Promise<T> {
        const url = `${this.getArea()}/`;
        const response = await this.client.post(url, { body: data });
    
        return response.json();
    }

    async update(id: string, data: Partial<T|CreateDocument>): Promise<T> {
        const url = `${this.getArea()}/${id}`;
        const response = await this.client.put(url,{ json: data });

        return response.json();
    }

    updateWithFormData(id: string, formData: FormData): Promise<T> {
        const url = `${this.getArea()}/${id}`;
        return this.client.post(url, { body: formData }).json();
    }

    delete(id: string) {
       return this.client.delete(`${this.getArea()}/${id}`).json();
    }
}