import React from "react";
import { createCountry } from '../../../Api/Queries/CountryQuery';
import Country from "./../../../Types/Country";
import {notify, notifyError} from "../../../Components/Utils/Notifications";
import CountryForm from "../Form/CountryForm";

interface Props {
    onCreate?: (country: Country) => void;
}

const CountryCreate: React.FC<Props> = ({ onCreate }) => {
    const handleSubmit =  async (values: Country) => {
        try {
            const country = await createCountry({...values});
            onCreate?.(country);
            notify("Pays cree avec succes");
        } catch (error) {
            notifyError("Erreur lors de la creation du pays");
        }
    };

    return <CountryForm onSubmit={handleSubmit} />;
}

export default CountryCreate;
