import React from 'react';
import { LocaleEnum, StatusEnum } from '../../../../Types/Enum';
import { Form, Formik } from 'formik';
import { Grid, Button } from '@mui/material';
import * as Yup from 'yup';
import CKEditorComponent from '../../../../Components/CKEditor/CKEditorComponent';
import { OrphanTranslation } from '../../../../Types/Orphan';
import StatusesSelector from '../../../../Components/Form/Select/StatusesSelector';

interface Props {
    defaultValues?: Partial<OrphanTranslation>;
    locale: LocaleEnum;
    onSubmit: (OrphanTranslation: OrphanTranslation) => void;
}

const OrphanTranslationForm: React.FC<Props> = ({ defaultValues, locale, onSubmit }) => {
    const initialValues: OrphanTranslation = {
        description: '',
        title: '',
        locale: locale,
        status: StatusEnum.DRAFT,
        ...defaultValues
    }

    const validationSchema = Yup.object().shape({
        description: Yup.string().required('La description est requise'),
        title: Yup.string().required('Le titre est requis'),
        status: Yup.string().required("Le statut est requis")
    });

    const handleSubmit = async (values: OrphanTranslation) => {
        onSubmit(values);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {() => (
                <Form>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <CKEditorComponent
                                label="Titre"
                                name="title"
                            />
                        </Grid>
                        <Grid item>
                            <CKEditorComponent
                                label="Description"
                                name="description"
                            />
                        </Grid>
                        <Grid item>
                            <StatusesSelector />
                        </Grid>
                        <Grid item container justifyContent="center">
                            <Button type="submit" variant="contained">Valider</Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

export default OrphanTranslationForm;
