import { Link } from "react-router-dom";
import { locale, statuses } from "../../../Components/Const/Transverse";
import { ApiColumn } from "../../../Services/TabUtils";
import Faq from "../../../Types/Faq";
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { GridRenderCellParams } from '@mui/x-data-grid';
import HtmlCell from "./HtmlCell";


interface FaqColumnsProps {
    onDeleteClick: (faq: Faq) => void;
}

export const faqColumns = ({ onDeleteClick }: FaqColumnsProps): ApiColumn<Faq>[] => [
    { field: 'id', headerName: 'ID'},
    { field:'locale', headerName: 'Locale', type: 'SelectOption', valueOptions: locale},
    { field: 'status', headerName: 'Status', type: 'singleSelect', valueOptions: statuses},
    { field: 'position', headerName: 'Position', type: 'number'},
    { field: 'question', headerName: 'Question',renderCell: (params: GridRenderCellParams) => <HtmlCell value={params.value as string} /> },
    { field: 'response', headerName: 'Response'},
    { field: 'createdAt', headerName: 'Cree le', type: 'date_api'},
    { field: 'actions', headerName: 'Actions', type: 'actions', getActions: (params: any) =>
        [
           <Link to={`/faqs/edit/${params.row.id}`}><EditRoundedIcon /> {'   '}</Link>,
           <DeleteIcon onClick={() => onDeleteClick(params.row)} />
        ]
    },
];