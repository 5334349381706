import React, { useState } from 'react';
import {Box, Button, Grid} from "@mui/material";
import UploadImage from "../../../../Components/Form/ImageComponents/UploadImage";
import Dialog from "../../../../Components/Layout/Dialog";
import { Form, Formik } from "formik";
import { addGalleryToOrphanage } from "../../../../Api/Queries/OrphanageQuery";
import { notify } from "../../../../Components/Utils/Notifications";
import Orphanage from "../../../../Types/Orphanage";

interface ModalImageProps {
    open: boolean;
    orphanage: Orphanage;
    onAdd: (images: File[]) => void;
    onClose: () => void;
}

const ModalImage: React.FC<ModalImageProps> = ({ open, orphanage, onAdd, onClose }) => {
    const [images, setImages] = useState<File[]>([]);

    const addGalleryImage = (values: { images: File[] }) => {
        const formData = new FormData();
        values.images.forEach((image, index) => {
            formData.append(`images[${index}]`, image);
        });

        addGalleryToOrphanage(orphanage.id!, formData).then(() => {
            notify('Les images ont bien été ajoutées à la galerie');
            onAdd(values.images);
            setImages(values.images);
        });
    };

    return (
        <Dialog title="Ajouter une photo à la galerie" open={open} onClose={onClose}>
            <Formik initialValues={{ images }} onSubmit={addGalleryImage}>
                <Form>
                    <Box mb={2}>
                    <Grid item>
                        <UploadImage className="UploadImage-input" name="images" label="Images de la galerie" isMultiple={true} showPreview />
                    </Grid>

                    <Grid item>
                        <Button type="submit" variant='contained'>Ajouter</Button>
                    </Grid>
                    </Box>
                </Form>
            </Formik>
        </Dialog>
    );
};

export default ModalImage;
