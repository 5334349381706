import {useQuery} from "@tanstack/react-query";
import Country from '../../Types/Country';
import CountryRepository from '../Repositories/CountryRepository';
import {Pagination} from "../../Types/Paginator";

export const useGetAllCountry = (pagination: Partial<Pagination> = {}) => useQuery({
    queryKey: ['countries'],
    queryFn: () => CountryRepository.getAll(pagination),
});

export const useGetByIdCountry = (id: string) => {
    return useQuery({
        queryKey: ['countries', id],
        queryFn: () => CountryRepository.getById(id)
    });
};

export const createCountry = async (newCountry: Country) => {
    return await CountryRepository.create(newCountry);
};

export const updateCountry = async (id: string, updatedCountry: Country) => {
    return await CountryRepository.update(id, updatedCountry);
};

export const deleteCountry = async (id: string) => {
    return await CountryRepository.delete(id);
};
