import React from 'react';
import {useField} from 'formik';
import {SelectOption} from "../../Types/Transverse";
import {FormControl, FormControlProps, FormHelperText, Select as SelectMaterial, InputLabel, MenuItem, SelectChangeEvent, CircularProgress} from "@mui/material";
import {SelectInputProps} from "@mui/material/Select/SelectInput";
import "./Select/Select.scss";

export interface SelectProps {
    name: string;
    label: string;
    emptyText?: string;
    onSelect?: (selectedValue: any) => void;
    options: SelectOption[];
    formControlProps?: Partial<FormControlProps>;
    selectProps?: Partial<SelectInputProps>;
    isLoading?: boolean;
}

const Select: React.FC<SelectProps> = ({ isLoading = false, name, label, options, emptyText, formControlProps, onSelect, selectProps}) => {
    const [field, meta] = useField(name);
    const hasError = meta.touched && meta.error !== undefined;

    const onChange = (e: SelectChangeEvent) => {
        field.onChange(e);
        onSelect?.(e.target.value);
    }

    const id = name + label;

    return (
        <FormControl fullWidth className="app-select" error={hasError} {...formControlProps}>
            <InputLabel id={id}>{label}</InputLabel>
            <SelectMaterial label={label} labelId={id} variant="outlined" {...field} onChange={onChange} {...selectProps}>
                { isLoading && <MenuItem className="select-item-loading" centerRipple><CircularProgress /></MenuItem>}
                { emptyText && <MenuItem value="">{emptyText}</MenuItem> }
                { options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>) }
            </SelectMaterial>
            {hasError && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    );
};

export default Select;
