import React , { useState }  from 'react';
import {Box, Grid, Typography} from '@mui/material';
import ApiTable from "../../../Components/DataDisplay/ApiTable";
import { useGetAllContact } from "../../../Api/Queries/ContactQuery";
import {contactColumns} from "./table";
import DeleteContact from "../Delete/DeleteContact";
import { useNavigate } from 'react-router-dom';
import Contact from '../../../Types/Contact';
import { useToggle } from "../../../Hooks/Transverse"; 

const ContactListPage: React.FC = () => {
    const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
    const { isOn: isShownModalDelete, on: showModalDelete, off: hideModalDelete } = useToggle(false);
    const { data: contacts, refetch } = useGetAllContact();
    const navigate = useNavigate();

    const handleDeleteClick = (contact: Contact) => {
        setSelectedContact(contact);
        showModalDelete();
    };

    const handleViewClick = (contact: Contact) => {
        navigate(`/contacts/view/${contact.id}`);
    };

    const handleCloseDeleteDialog = () => {
        setSelectedContact(null);
        hideModalDelete();
    };
    return (
        <Box>
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Liste des prises de contact</Typography>
            </Grid>

            <ApiTable query={useGetAllContact}  columns={contactColumns({ onDeleteClick: handleDeleteClick, onViewClick: handleViewClick })} />

            {selectedContact && (
                <DeleteContact
                    contact={selectedContact}
                    open={isShownModalDelete}
                    onClose={handleCloseDeleteDialog}
                    onDelete={refetch}
                />
            )}
        </Box>
    );
};

export default ContactListPage;
