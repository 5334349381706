import {useQuery} from "@tanstack/react-query";
import { Pagination } from "../../Types/Paginator";
import AdminRepository from "../Repositories/AdminRepository";
import { CreateAdmin } from "../../Types/Admin";

export const useGetAllAdmin = (pagination: Partial<Pagination> = {}) => useQuery ({
    queryKey: ['admins',pagination],
    queryFn: () =>AdminRepository.getAll(pagination),
});

export const createAdmin = async (newAdmin: CreateAdmin) =>{
    return await AdminRepository.create(newAdmin);
};

export const deleteAdmin = async (id: string) =>{
    return await AdminRepository.delete(id);
};

export const updateAdmin = async (id: string, updateAdmin: CreateAdmin) => {
    return await AdminRepository.update(id, updateAdmin);
};

export const useGetAdminById = (id: string) => {
    return useQuery({
        queryKey: ['admin', id],
        queryFn: async () =>{
            return await AdminRepository.getById(id);
        }
    })
}