import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import { useGetContactById } from '../../../Api/Queries/ContactQuery';
import Contact from '../../../Types/Contact';
import Input from '../../../Components/Form/Input';
import Loading from '../../../Components/Feeback/Loading';

const ContactView: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { data: contact, isLoading, error } = useGetContactById(id!);
    
    if (isLoading) {
        return <Loading show={isLoading} title="Chargement en cours..." />;
    }

    if (error || !contact) {
        return <Typography variant="h6" color="error">Contact not found</Typography>;
    }

    const initialValues: Contact = {
        id: contact.id,
        fullName: contact.fullName,
        phone: contact.phone,
        email: contact.email,
        subject: contact.subject,
        message: contact.message,
    };

    return (
        <Grid container direction="column">
            <Grid item marginY={5}>
            <Typography align="center" gutterBottom variant="h3">Details de pris de contact</Typography>
            </Grid>
            <Formik initialValues={initialValues} onSubmit={() => {}}>
                <Form>
                    <Grid container spacing={3}  direction="column" >
                        <Grid item>
                            <Input label="ID" name="id" disabled/>
                        </Grid>
                        <Grid item>
                            <Input label="Nom complet" name="fullName" disabled />
                        </Grid>
                        <Grid item>
                            <Input label="Téléphone" name="phone" disabled/>
                        </Grid>
                        <Grid item>
                            <Input label="Email" name="email" disabled />
                        </Grid>
                        <Grid item>
                            <Input label="Sujet" name="subject" disabled/>
                        </Grid>
                        <Grid item>
                            <Input label="Message" name="message" disabled/>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </Grid>
    );
};

export default ContactView;
