import React from "react";

import { Sponsorship } from "../../../../../Types/Sponsorship";
import { deleteSponsorship } from "../../../../../Api/Queries/SponsorshipQuery";
import { notify, notifyError } from "../../../../../Components/Utils/Notifications";
import ConfirmationDialog from "../../../../../Components/Layout/ConfirmationDialog";

interface Props {
    sponsor: Sponsorship;
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteSponsor: React.FC<Props> = ({ sponsor, open, onClose, onDelete }) => {
    const handleDelete = async () => {
        try {
            await deleteSponsorship(sponsor.id);
            onClose();
            onDelete();
            notify('lien de parrainage supprimé avec succès');
        } catch (error) {
            notifyError('Erreur lors de la suppression');
        }
    };
    
    return (
        <ConfirmationDialog
            open={open}
            onCancel={onClose}
            title="Supprimer lien de parrainage"
            onConfirm={handleDelete}
            message="Êtes-vous sûr de vouloir supprimer ce lien de parrainage ?"
        />
    );
};

export default DeleteSponsor;
