import React from "react";
import TabComponents from "../../../../Components/Form/TabComponent";
import OrphanageTranslationCreate from "../OrphanageTranslationPage/Create/OrpahanageTranslationCreate";
import { LocaleEnum } from "../../../../Types/Enum";

const OrphanageTranslationTabs: React.FC = () => {
  const tabs = [
    { label: 'Francais', value: '1', component: <OrphanageTranslationCreate locale={LocaleEnum.FR}/> },
    { label: 'English', value: '2', component: <OrphanageTranslationCreate locale={LocaleEnum.EN}/> },
  ];

  return <TabComponents tabs={tabs} />;
};

export default OrphanageTranslationTabs;
