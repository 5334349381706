import React from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

interface TabProps {
  label: string;
  value: string;
  component: React.ReactNode;
}

interface ReusableTabsProps {
  tabs: TabProps[];
}

const TabComponents: React.FC<ReusableTabsProps> = ({ tabs }) => {
  const [value, setValue] = React.useState(tabs[0].value);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="tabs example">
            {tabs.map((tab) => (
              <Tab label={tab.label} value={tab.value} key={tab.value} />
            ))}
          </TabList>
        </Box>
        {tabs.map((tab) => (
          <TabPanel value={tab.value} key={tab.value}>
            {tab.component}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

export default TabComponents;
