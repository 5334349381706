import { type iNotification, Store } from 'react-notifications-component';

export const notify = (message: string, title: string = "", options: Partial<iNotification> = {}) => {
    const defaultOptions: Partial<iNotification> = {
        insert: 'top',
        type: 'success',
        container: 'top-right',
        dismiss: {
            duration: 2000,
            onScreen: true,
            showIcon: true
        }
    };

    Store.addNotification({
        ...defaultOptions,
        ...options,
        title,
        message
    } as iNotification);
};

export const notifyError = (message: string, title: string = "", options: Partial<iNotification> = {}) => {
    notify(message, title, {
        type: "danger"
    });
}