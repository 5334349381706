import React from 'react';
import { Formik } from 'formik';
import {Button, Grid, Typography} from '@mui/material';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import Loading from "../../../Components/Feeback/Loading";
import { updateOrphan, useGetOrphanById } from "../../../Api/Queries/OrphanQuery";
import { UpdateOrphan } from "../../../Types/Orphan";
import dayjs from "dayjs";
import { notify, notifyError } from "../../../Components/Utils/Notifications";
import OrphanForm from "../Form/OrphanForm";
import { useToggle } from '../../../Hooks/Transverse';
import ModalSponsor from './Sponsors/Create/ModalSponsor';

const EditOrphanFile = () => {
    const { id } = useParams<{ id?: string | undefined }>();
    const { data: orphan, isLoading, refetch } = useGetOrphanById(id!);
    const { isOn: openAddSponsorModal, on: showAddSponsorModal, off: hideAddSponsorModal } = useToggle();

    const validationSchema = Yup.object().shape({
        lastName: Yup.string().required('Le nom est requis'),
        firstName: Yup.string().required('Le prénom est requis'),
        birthDate: Yup.date().required('La date de naissance est requise').nullable(),
        educationLevel: Yup.string().required('Le niveau scolaire est requis'),
        position: Yup.number().required('La position est requise'),
        status: Yup.string().required('Le statut est requis'),
        gender: Yup.string().required('Le genre est requis'),
    });

    const update = async (values: UpdateOrphan) => {
        try {
            const formData = new FormData();
            formData.append('firstName', values.firstName);
            formData.append('lastName', values.lastName);
            formData.append('birthDate', values.birthDate ? dayjs(values.birthDate).format('YYYY-MM-DD') : '');
            formData.append('educationLevel', values.educationLevel);
            formData.append('position', values.position.toString());
            formData.append('status', values.status);
            formData.append('gender', values.gender);
            if (values.orphanage) {
                formData.append('orphanage', values.orphanage);
            }
            if (values.photoFile) {
                formData.append('photoFile', values.photoFile);
            }

            await updateOrphan(id!, formData);
            notify('Orphelin modifié avec succès');
            refetch();
        } catch (error) {
            notifyError('Erreur lors de la modification de l\'orphelin');
        }
    };

    if (isLoading || !orphan) {
        return <Loading show={isLoading} title="Chargement en cours..." />;
    }

    const initialValues: UpdateOrphan = {
        ...orphan,
        orphanage: orphan.orphanage?.id || ''
    };

    return (
        <Grid container direction="column">
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Modifier un orphelin</Typography>
            </Grid>
            <Grid item>
                <Formik initialValues={initialValues} onSubmit={update} validationSchema={validationSchema}>
                    <OrphanForm isEdit={true} imagePreviewUrl={orphan?.photoFileUrl} />
                </Formik>
            </Grid>
            <Grid item marginTop={3}>
                <Button variant="contained" color="primary" onClick={showAddSponsorModal}>
                    Ajouter un parrain
                </Button>
            </Grid>
           
            <ModalSponsor open={openAddSponsorModal} onClose={hideAddSponsorModal} orphan={id!} />
        </Grid>

    );
};

export default EditOrphanFile;
