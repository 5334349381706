import {AppBar, Avatar, Box, Grid, IconButton, Menu, MenuItem, PopoverOrigin, Toolbar} from "@mui/material";
import React, {useState} from "react";
import {useToggle} from "../Hooks/Transverse";
import MenuIcon from '@mui/icons-material/Menu';
import {SideMenu} from "./Layout/SideMenu";
import {Auth} from "../Services/Auth";
import {useNavigate} from "react-router-dom";
import {useUser} from "../Hooks/User";
import {deepOrange} from "@mui/material/colors";

interface Props {
    children: React.ReactNode;
}

const Layout: React.FC<Props> = ({children}) => {
    const {isOn, toggle} = useToggle(false);

    return <Grid sx={[
        {transition: 'all .4s linear'},
        isOn ? {paddingLeft: "200px"}: {paddingLeft: "75px"},
    ]}>
        <AppBar position="static">
            <MenuLayout isOn={isOn} toggle={toggle}/>
        </AppBar>

        <SideMenu open={isOn} />
        <Box component="section" sx={{ p: 4 }}>
            {children}
        </Box>
    </Grid>
}

const MenuLayout: React.FC<any>= ({toggle}) => {
    return <Toolbar>
        <Grid container alignItems="center">
            <Grid item>
                <IconButton onClick={toggle} size="large" edge="start" color="inherit" aria-label="menu" sx={[
                    {transition: "all .4s linear"},
                ]}>
                    <MenuIcon  />
                </IconButton>
            </Grid>

            <Grid marginLeft="auto">
                <UserMenu />
            </Grid>
        </Grid>
    </Toolbar>
}

const UserMenu = () => {
    const navigate = useNavigate();

    const {isOn, on, off} = useToggle(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const anchorOrigin: PopoverOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        on();
    };
    const handleClose = () => {
        setAnchorEl(null);
        off();
    };

    const logout = () => {
        Auth.logout();
        navigate('/login');
    }

    return (<>
        <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleClick} color="inherit">
            <Avatar sizes="small" sx={{ width: 40, height: 40, bgcolor: deepOrange[500] }}>{useUser().firstName[0]}</Avatar>
        </IconButton>
        <Menu id="menu-appbar" keepMounted anchorEl={anchorEl} anchorOrigin={anchorOrigin} transformOrigin={anchorOrigin} open={isOn} onClose={handleClose}>
            <MenuItem onClick={logout}>Deconnexion</MenuItem>
        </Menu>
    </>)
}

export default Layout;