import React from "react";
import {createCity} from "../../../Api/Queries/CityQuery";
import City, {CreateCity} from "../../../Types/City";
import "../Form/CityForm.scss";
import {notify, notifyError} from "../../../Components/Utils/Notifications";
import CityForm from "../Form/CityForm";

interface AddCityModalProps {
    onCreate?: (city: City) => void;
}

const CityCreate: React.FC<AddCityModalProps> = ({ onCreate }) => {
    const handleSubmit = async (values: CreateCity) => {
        try {
            const cityCreated = await createCity({...values});
            notify('La ville a ete cree');
            onCreate?.(cityCreated);
        } catch (error) {
            notifyError('Erreur lors de la creation de la ville');
        }
    };

    return (<CityForm onSubmit={handleSubmit} />);
}

export default CityCreate;
