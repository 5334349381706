import React from "react";
import { CreateAdmin, UserLogged } from "../../../Types/Admin";
import { createAdmin } from "../../../Api/Queries/AdminQuery"; 
import { notify, notifyError } from "../../../Components/Utils/Notifications";
import AdminForm from "../Form/AdminForm";

interface Props {
    onCreate?: (admin: UserLogged) => void;
}

const AdminCreate: React.FC<Props> = ({ onCreate }) => {
    const handleSubmit = async (values: CreateAdmin) => {
        try {
            const admin = await createAdmin({ ...values });
            onCreate?.(admin);
            notify("Admin créé avec succès");
        } catch (error) {
            notifyError("Erreur lors de la création de l'administrateur.");
        }
    };

    return (
        <AdminForm onSubmit={handleSubmit} />
    );
}

export default AdminCreate;
