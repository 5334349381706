import { BaseRepository } from './BaseRepository';
import { Orphan, CreateOrphan } from '../../Types/Orphan';
import {Paginator} from "../../Types/Paginator";
import { SearchFilters } from '../../Types/SearchFilter';
import qs from 'qs';

class SearchRepository extends BaseRepository<Orphan, CreateOrphan> {
    protected getArea(): string {
        return 'search';
    }
    
    async getSearchOrphan(filters: SearchFilters): Promise<Paginator<Orphan>> {
        const response = await this.client.get(`${this.getArea()}/orphans`, { searchParams: qs.stringify(filters, { encode: false }) });
        return response.json();
    }
}
export default new SearchRepository();
