import { Grid, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { updateAdmin, useGetAdminById } from "../../../Api/Queries/AdminQuery";
import {pick} from "lodash";
import { CreateAdmin } from "../../../Types/Admin";
import { notify } from "../../../Components/Utils/Notifications";
import AdminForm from "../Form/AdminForm";
import Loading from "../../../Components/Feeback/Loading";

type RouteParams = keyof {
    id: string;
}

const AdminEditPage: React.FC = () =>{
    const { id } = useParams<RouteParams>();
    const {isLoading, isSuccess, data: admin} = useGetAdminById(id!);

    const handleSubmit = (values: CreateAdmin) => {
        const admin = pick(values, ['email', 'firstName', 'lastName', 'password']);
        updateAdmin(id!, admin).then(() => {
            notify('Administrateur mis a jour');
        }); 
    }

    const defaultValue: Partial<CreateAdmin> = {
        ...admin,
    }

    return(
        <>
        <Loading show={isLoading} />
        <Grid container direction="column">
            <Grid item marginY={5}>
            <Typography align="center" gutterBottom variant="h3">Éditer un administrateur</Typography>
            </Grid>
            <Grid item>
                {
                    isSuccess && <AdminForm onSubmit={handleSubmit} defaultValues={defaultValue}/>
                }
            </Grid>
        </Grid>
        </>
    );
};
export default AdminEditPage;