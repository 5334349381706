import { useQuery } from "@tanstack/react-query";
import { Pagination } from "../../Types/Paginator";
import OrphanRepository from "../Repositories/OrphanRepository";
import { OrphanTranslation } from "../../Types/Orphan";

export const useGetAllOrphans = (pagination: Partial<Pagination> = {}) => {
    return useQuery({
        queryKey: ['orphan', pagination],
        queryFn: async () => {
            return await OrphanRepository.getAll(pagination);
        }
    });
};

export const useGetAllOrphansByOrphanage = (orphanageId: string | undefined, pagination: Partial<Pagination> = {}) => {
    const filters = orphanageId ? { ...pagination, filter: { "orphanage.id": orphanageId } }:{};
    return useQuery({
        queryKey: ['orphan', orphanageId, pagination],
        queryFn: () => OrphanRepository.getAll(filters),
    });
};

export const useGetOrphanById = (id: string) => {
    return useQuery({
        queryKey: ['orphan', id],
        queryFn: async () => {
            return await OrphanRepository.getById(id);
        }
    });
};

export const createOrphan = async (orphan: FormData) => {
    return await OrphanRepository.createWithFormData(orphan);
};

export const updateOrphan = async (id: string, update: FormData) => {
    return await OrphanRepository.updateOrphan(id, update);
};

export const createTranslation = async (id:string, traduction: OrphanTranslation) =>{
    return await OrphanRepository.createTranslation(id, traduction);
}