import OrphanageRepository from '../Repositories/OrphanageRepository';
import Orphanage, {CreateOrphanage} from '../../Types/Orphanage';
import { OrphanageTranslation } from '../../Types/OrphanageTranslation';
import {useQuery} from "@tanstack/react-query";
import {Pagination} from "../../Types/Paginator";

export const useGetAllOrphanage = (pagination: Partial<Pagination> = {}) => {
    return useQuery({
        queryKey: ['orphanage', pagination],
        queryFn: async () => {
            return await OrphanageRepository.getAll(pagination);
        }
    });
};

export const useGetOrphanageById = (id: string) => {
    return useQuery({
        queryKey: ['orphanage', id],
        queryFn: async () => {
            return await OrphanageRepository.getById(id);
        }
    });
};

export const createOrphanage = async (orphanage: CreateOrphanage) => {
    return await OrphanageRepository.create(orphanage);
};

export const updateOrphanage = async (id: string, update: Orphanage) => {
    return await OrphanageRepository.update(id, update);
};

export const updateOrphanageGallery = async (id: string, formData: FormData) => {
    return await OrphanageRepository.updateGallery(id, formData);
};

export const addGalleryToOrphanage = async(id: string, data: FormData) => {
    return await OrphanageRepository.addGallery(id, data);
}

export const deleteGallery = (id: string, orphanage: Orphanage) => {
    return OrphanageRepository.deleteGallery(id, orphanage);
};

export const addTranslationToOrphanage = async (id: string, data: OrphanageTranslation) => {
    return await OrphanageRepository.addTranslation(id, data);
}

export const deleteOrphanage = (id: string) => {
    return OrphanageRepository.delete(id);
};

export const assignOrphanToOrphanage = async (orphanId: string, orphanageId: string) => {
    return await OrphanageRepository.assignOrphanToOrphanage(orphanId, orphanageId);
};