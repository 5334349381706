import { useQuery } from '@tanstack/react-query';
import StatisticRepository from '../Repositories/StatisticRepository';

export const useGetStatisticsOrphanages = () => {
    return useQuery({
        queryKey: ['statisticsOrphanages'],
        queryFn: async () => {
            return await StatisticRepository.getStatisticsOrphanages();
        }
    });
};

export const useGetStatisticsOrphans = () => {
    return useQuery({
        queryKey: ['statisticsOrphans'],
        queryFn: async () => {
            return await StatisticRepository.getStatisticsOrphans();
        }
    });
};
