import { BaseRepository } from './BaseRepository';
import { User } from '../../Types/User';
import { Paginator } from '../../Types/Paginator';
import { SearchFilters } from '../../Types/SearchFilter';
import qs from 'qs';

class UserRepository extends BaseRepository<User, any> {
    protected getArea(): string {
        return 'users';
    }

    async getSearchUser(filters: SearchFilters): Promise<Paginator<User>> {
        const response = await this.client.get(`${this.getArea()}/search`, {
            searchParams: qs.stringify(filters, { encode: false })
        });
        return response.json();
    }
}

export default new UserRepository();
