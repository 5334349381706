import { useQuery } from "@tanstack/react-query";
import Faq from "../../Types/Faq";
import { Pagination } from "../../Types/Paginator";
import FaqRepository from "../Repositories/FaqRepository";

export const useGetAllFaq = (pagination: Partial<Pagination> = {}) => useQuery({
    queryKey: ['faqs'],
    queryFn: () => FaqRepository.getAll(pagination),
});

export const createFaq = async (newFaq: Faq) =>{
    return await FaqRepository.create(newFaq);
}

export const updateFaq = async ( id: string, updateFaq: Faq) =>{
    return await FaqRepository.update(id, updateFaq);
}

export const deleteFaq = async (id: string) =>{
    return await FaqRepository.delete(id);
}
export const useGetByIdFaq = (id: string) => {
    return useQuery({
        queryKey: ['faqs', id],
        queryFn: () => FaqRepository.getById(id)
    });
};