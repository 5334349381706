import CityRepository from '../Repositories/CityRepository';
import City, {CreateCity} from '../../Types/City';
import {useQuery} from "@tanstack/react-query";
import {Pagination} from "../../Types/Paginator";
import CountryRepository from "../Repositories/CountryRepository";

export const useGetAllCity = (countryId?: string) => {
    const filters = countryId ? {filter: {"country.id": countryId}} : {};

    return useQuery({
        queryKey: ['cities', countryId],
        queryFn: () => CityRepository.getAll(filters),
    });
};

export const useGetAllCityPaginated = (pagination: Partial<Pagination> = {}) => useQuery({
    queryKey: ['cities', pagination],
    queryFn: () => CityRepository.getAll(pagination),
});

export const useGetCityById = (id: string) => {
    return useQuery({
        queryKey: ['city', id],
        queryFn: async () => {
            return await CityRepository.getById(id);
        }
    });
};

export const createCity = async (newCity: CreateCity) => {
    return await CityRepository.create(newCity);
};

export const updateCity = async (id: string, updatedCity: CreateCity) => {
    return await CityRepository.update(id, updatedCity);
};

export const deleteCity = async (id: string) => {
    return await CityRepository.delete(id);
};
