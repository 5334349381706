import React from "react";
import Dialog from "../../../Components/Layout/Dialog";
import CityCreate from "./CityCreate";
import City from "../../../Types/City";

interface Props {
    open: boolean;
    onClose: () => void;
    onCreate?: (country: City) => void;
}

const ModalCityCreate: React.FC<Props> = ({ open, onClose, onCreate }) => {
    return (
        <Dialog open={open} title="Ajouter une ville" onClose={onClose}>
            <CityCreate onCreate={onCreate} />
        </Dialog>
    );
}

export default ModalCityCreate;
