enum StatusEnum {
    PUBLISHED = "published",
    UNPUBLISHED = "unpublished",
    DRAFT = "draft",
}

export { StatusEnum };

enum EducationLevelEnum{
    PRIMARY = 'primary',
    COLLEGE = 'college',
    HIGH_SCHOOL = 'high_school',
}

export { EducationLevelEnum };

enum LocaleEnum{
     FR = 'fr',
     EN = 'en',
}
export { LocaleEnum };

enum GenderEnum{
    Male = 'male',
    Female = 'female',
}

export { GenderEnum };

enum SubjectEnum {
    APPOINTMENT_REQUEST = 'appointment_request',
    INFORMATION_REQUEST = 'information_request',
    REFUND = 'refund',
    CUSTOMIZED_REFERRAL = 'customized_referral',
    OTHER = 'other',
}

export { SubjectEnum };
