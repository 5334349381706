import React, { useState, useEffect} from 'react';
import { useField } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormControl, FormControlProps } from "@mui/material";
import { Paginator } from '../../Types/Paginator';
import { UseQueryResult } from '@tanstack/react-query';

interface Props {
    name: string;
    label?: string;
    query: (searchTerm: string) => UseQueryResult<Paginator<any>>; 
    getOptionLabel: (option: any) => string;
    getOptionKey?: (option: any) => any;
    formControlProps?: Partial<FormControlProps>;
    onSelect?: (option: any | null) => void;
    placeholder:string;
    initialValue?: any;
}

const AutocompleteField: React.FC<Props> = ({ name, label, query, getOptionLabel, getOptionKey, formControlProps, onSelect, placeholder, initialValue }) => {
    const [field, meta, helper] = useField(name);
    const [inputValue, setInputValue] = useState(initialValue ? getOptionLabel(initialValue) : '');
    const queryResult = query(inputValue); 

    useEffect(() => {
        if (initialValue) {
            helper.setValue(initialValue);
        }
    }, [initialValue, helper]);

    const handleChange = (event: React.SyntheticEvent, value: any) => {
        helper.setValue(value);
        onSelect?.(value);
    };

    return (
        <FormControl fullWidth {...formControlProps}>
            <Autocomplete
                freeSolo
                {...field}
                options={queryResult.data?.results || []} 
                getOptionKey={getOptionKey}
                getOptionLabel={getOptionLabel}
                onChange={handleChange}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        label={label}
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.touched && meta.error}
                    />
                )}
            />
        </FormControl>
    );
};

export default AutocompleteField;