import React from "react";
import {Form, Formik} from "formik";
import {Login as LoginType} from "../../Types/Admin";
import {Button, Container, Grid} from "@mui/material";
import Input from "../../Components/Form/Input";
import {loginQuery} from "../../Api/Queries/AuthQuery";
import {notify, notifyError} from "../../Components/Utils/Notifications";
import {BlueBox} from "../../Components/Layout/Box";
import {Auth} from "../../Services/Auth";
import {setUserLogged} from "../../GlobalState/AuthState";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import "./Login.scss";

const Login: React.FC = () => {
    const navigate = useNavigate();
    const loginInfo: LoginType = {password: '', username: ''};

    const login = async (values: LoginType) => {
        try {
            const token = await loginQuery(values);
            notify('Connexion avec succes');
            Auth.login(token);
            setUserLogged(token.user);
            setTimeout(() => navigate('/'), 1500);
        } catch (e) {
            notifyError('Login ou mot de passe incorrect.');
        }
    }

    const schema = Yup.object<Partial<LoginType>>({
        username: Yup.string().email().required('Veuillez renseigner l email.'),
        password: Yup.string().required('Veuillez renseigner le mot de passe.')
    });

    return <div id="app-login" className="main-container">
        <Container fixed maxWidth="md">
            <Formik initialValues={loginInfo} onSubmit={login} validationSchema={schema}>
                <Form>
                    <Grid maxWidth="md" container direction="column" height="100vh" justifyContent="center">
                        <BlueBox>
                            <Grid item container rowSpacing={3} direction="column">
                                <h1>Connexion</h1>
                                <Grid item>
                                    <Input label="Email" name="username" type="email" />
                                </Grid>

                                <Grid item>
                                    <Input label="Mot de passe" name="password" type="password" />
                                </Grid>

                                <Grid item container justifyContent="center">
                                    <Button variant="contained" type="submit">Connexion</Button>
                                </Grid>
                            </Grid>
                        </BlueBox>
                    </Grid>
                </Form>
            </Formik>
        </Container>
    </div>;
}

export default Login;