import {SelectOption} from "../../Types/Transverse";
import {EducationLevelEnum, StatusEnum, LocaleEnum, GenderEnum, SubjectEnum} from "../../Types/Enum";

export const statuses: SelectOption<string>[] = [
    {
        value: StatusEnum.DRAFT,
        label: "brouillon",
    },
    {
        value: StatusEnum.PUBLISHED,
        label: "publie",
    },
    {
        value: StatusEnum.UNPUBLISHED,
        label: "depublie",
    },
];

export const educationLevels: SelectOption<string>[] = [
    {
        value: EducationLevelEnum.PRIMARY,
        label: "Primaire",
    },
    {
        value: EducationLevelEnum.COLLEGE,
        label: "Collège",
    },
    {
        value: EducationLevelEnum.HIGH_SCHOOL,
        label: "Lycée",
    },
];

export const locale: SelectOption<string>[] = [
    {
        value: LocaleEnum.EN,
        label: "Anglais",
    },
    {
        value: LocaleEnum.FR,
        label: "Francais"
    }
];

export const genders: SelectOption<string>[] = [
    {
        value: GenderEnum.Female,
        label: "Feminin",
    },
    {
        value: GenderEnum.Male,
        label: "Masculin"
    }
]

export const subjects: SelectOption<string>[] = [
    {
        value: SubjectEnum.APPOINTMENT_REQUEST,
        label: "Demande de rendez-vous",
    },
    {
        value: SubjectEnum.INFORMATION_REQUEST,
        label: "Demande d'informations",
    },
    {
        value: SubjectEnum.REFUND,
        label: "Remboursement",
    },
    {
        value: SubjectEnum.CUSTOMIZED_REFERRAL,
        label: "Parrainage personnalisé",
    },
    {
        value: SubjectEnum.OTHER,
        label: "Autres",
    }
]
