import React from "react";
import { Form, Formik } from "formik";
import { Grid ,Button} from "@mui/material";
import * as Yup from "yup";
import { OrphanageTranslation } from "../../../../../Types/OrphanageTranslation";
import { LocaleEnum, StatusEnum } from "../../../../../Types/Enum";
import CKEditorComponent from "../../../../../Components/CKEditor/CKEditorComponent";
import TitleConfig from "../../../../../Components/CKEditor/Config/TitleConfig";
import Input from "../../../../../Components/Form/Input";
import StatusesSelector from "../../../../../Components/Form/Select/StatusesSelector";

interface Props {
    defaultLocale: LocaleEnum;
    defaultValues?: Partial<OrphanageTranslation>;
    onSubmit: (orphanageTranslation: OrphanageTranslation) => void;
}

const OrphanageTranslationForm: React.FC<Props> = ({ defaultLocale, defaultValues, onSubmit }) => {
    const initialValues = {
        locale: defaultLocale || LocaleEnum.FR,
        status: StatusEnum.DRAFT,
        title: "",
        description: "",
        shortDescription: "",
        slug: "",
        ...defaultValues,
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Le titre est requis"),
        description: Yup.string().required("La description est requise"),
        shortDescription: Yup.string().required("La description court est requise"),
        slug: Yup.string().required("Le slug est requis"),
        status: Yup.string().required("Le statut est requis"),
    });

    const handleSubmit = async (values: OrphanageTranslation) => {
        onSubmit(values);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
                <Form>
                    <Grid container spacing={3} direction="column">
                        <Grid item>
                            <CKEditorComponent label="Titre" name="title" config={TitleConfig} striped={true}/>
                        </Grid>
                        <Grid item>
                            <CKEditorComponent label="Description Court" name="shortDescription"/>
                        </Grid>
                        <Grid item>
                            <CKEditorComponent label="Description" name="description"/>
                        </Grid>
                        <Grid item>
                            <Input label="Slug " name="slug" />
                        </Grid>
                        <Grid item>
                            <StatusesSelector />
                        </Grid>
                        <Grid item container justifyContent="center">
                            <Button type="submit" variant="contained"> Valider </Button>
                        </Grid>
                    </Grid>
                </Form>
        </Formik>
    );
};

export default OrphanageTranslationForm;