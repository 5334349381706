import React from "react";
import {Dialog as BaseDialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    open: boolean;
    children: React.ReactNode;
    title: string;
    actions?: React.ReactNode;
    onClose?: () => void;
    showClose?: boolean;
}

const Dialog:React.FC<Props> = ({open, children, title, actions, onClose, showClose = true}) => {
    return <BaseDialog open={open} disableEscapeKeyDown fullWidth>
        {
            showClose && <CloseIcon sx={{position: "absolute", top: 5, right: 5, cursor: "pointer"}} onClick={onClose}></CloseIcon>
        }

        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
            {children}
        </DialogContent>
        {
            actions && (<DialogActions>
                {actions}
            </DialogActions>)
        }
    </BaseDialog>;
}

export default Dialog;