import React from "react";
import Faq from "../../../Types/Faq";
import ConfirmationDialog from "../../../Components/Layout/ConfirmationDialog";
import { notify, notifyError } from "../../../Components/Utils/Notifications";
import { deleteFaq } from "../../../Api/Queries/FaqQuery";

interface Props {
    faq: Faq;
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteFaq: React.FC<Props> = ({ faq, open, onClose, onDelete }) => {
    const handleDelete = async () => {
        if (!faq.id) {
            notifyError('ID de la FAQ manquant');
            return;
        }

        try {
            await deleteFaq(faq.id);
            onClose();
            onDelete();
            notify('FAQ supprimée avec succès');
        } catch (error) {
            notifyError('Erreur lors de la suppression de la FAQ');
        }
    };

    return (
        <ConfirmationDialog
            open={open}
            onCancel={onClose}
            title="Supprimer la FAQ"
            onConfirm={handleDelete}
            message="Êtes-vous sûr de vouloir supprimer cette FAQ ?"
        />
    );
};

export default DeleteFaq;
