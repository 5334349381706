import React from "react";
import Contact from "../../../Types/Contact";
import ConfirmationDialog from "../../../Components/Layout/ConfirmationDialog";
import { notify, notifyError } from "../../../Components/Utils/Notifications";
import { deleteContact} from "../../../Api/Queries/ContactQuery";

interface Props {
    contact: Contact;
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteContact: React.FC<Props> = ({ contact, open, onClose, onDelete }) => {
    const handleDelete = async () => {
        try {
            await deleteContact(contact.id);
            onClose();
            onDelete();
            notify('Prise de contact supprimé avec succès');
        } catch (error) {
            notifyError('Erreur lors de la suppression de prise de contact');
        }
    };
    
    return (
        <ConfirmationDialog
            open={open}
            onCancel={onClose}
            title="Supprimer prise de contact"
            onConfirm={handleDelete}
            message="Êtes-vous sûr de vouloir supprimer cette prise de contact ?"
        />
    );
};

export default DeleteContact;
