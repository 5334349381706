import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useField } from 'formik';

interface CKEditorComponentProps {
    label: string;
    name: string;
    config?: object;
    striped?: boolean; 
}

const CKEditorComponent: React.FC<CKEditorComponentProps> = ({ label, name, config, striped= false }) => {
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;

    const handleChange = (event: any, editor: any) => {
        let data = editor.getData();
        if (striped) {
            data = data.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, ''); 
        }
        setValue(data);
    };

    return (
        <div>
            <label>{label}</label>
            <CKEditor
                editor={ClassicEditor}
                config={config}
                data={field.value}
                onChange={handleChange}
            />
            {meta.touched && meta.error ? <div style={{ color: 'red' }}>{meta.error}</div> : null}
        </div>
    );
};

export default CKEditorComponent;
