import React from "react";
import { Grid, Typography} from "@mui/material";
import CityCreate from "./CityCreate";

const CityCreatePage: React.FC = () => {
    return (
        <Grid container direction="column">
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Creation d'une nouvelle ville</Typography>
            </Grid>

            <Grid item>
                <CityCreate />
            </Grid>
        </Grid>
    );
}

export default CityCreatePage;
