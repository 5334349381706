import AddHomeIcon from '@mui/icons-material/AddHome';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import MapIcon from '@mui/icons-material/Map';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import React from "react";
import {Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import BarChartIcon from '@mui/icons-material/BarChart';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ThreePIcon from '@mui/icons-material/ThreeP';

const sideMenuItem = [
       {
        icon: <BarChartIcon/>,
        label: 'Statistiques',
        route: '/',
    },
    {
        icon: <AddHomeIcon/>,
        label: 'Orphelinats',
        route: '/orphanages',
    },
    {
        icon: <PublicTwoToneIcon/>,
        label: 'Pays',
        route: '/countries',
    },
    {
        icon: <MapIcon/>,
        label: 'Villes',
        route: '/cities',
    },
    {
        icon: <SupervisorAccountIcon/>,
        label: 'Admins',
        route: '/admins',
    },
    {
        icon: <PostAddIcon/>,
        label: 'Orphelins',
        route: '/orphans',
    },
    {
        icon: < LiveHelpIcon/>,
        label: 'faqs',
        route: '/faqs',
    },
    {
        icon: < ThreePIcon/>,
        label: 'contacts',
        route: '/contacts',
    }
];

interface SideMenuItemProps {
    item: typeof sideMenuItem[0],
    open: boolean,
}

const SideMenuItem: React.FC<SideMenuItemProps> = ({open, item}) => {
    const Icon = () => item.icon;

    return <ListItem disablePadding sx={{display: "block"}}>
        <Link to={item.route}>
            <ListItemButton sx={{minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5}}>
                <ListItemIcon sx={{minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center"}}>
                    {Icon()}
                </ListItemIcon>
                <ListItemText primary={item.label} sx={{opacity: open ? 1 : 0}}/>
            </ListItemButton>
        </Link>
    </ListItem>;
}

export const SideMenu: React.FC<{ open: boolean }> = ({open}) => {
    return (
        <Drawer
            variant="persistent"
            anchor="left"
            sx={{
                width: open ? 200 : 75,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    transition: "all .4s linear",
                    width: open ? 200 : 75,
                    boxSizing: 'border-box',
                },
            }}
            open={true}
        >
            <List>
                {
                    sideMenuItem.map(item => <SideMenuItem key={item.route} item={item} open={open}/>)
                }
            </List>
        </Drawer>
    )
}