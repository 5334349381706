import { Link } from "react-router-dom";
import { ApiColumn } from "../../../Services/TabUtils";
import { Admin } from "../../../Types/Admin";
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from "@mui/icons-material/EditRounded";

interface AdminColumnsProps {
    onDeleteClick: (admin: Admin) => void;
}

export const adminColumns = ({ onDeleteClick }: AdminColumnsProps): ApiColumn<Admin>[] => [
    { field: 'id', headerName: 'ID' },
    { field: 'firstName', headerName: 'Prénom' },
    { field: 'lastName', headerName: 'Nom de Famille' },
    { field: 'email', headerName: 'Email' },
    { field: 'createdAt', headerName: 'Créé le', type: 'date_api' },
    {field: 'actions', headerName: 'Actions', type: 'actions', getActions: (params: any) =>
         [
            <Link to={`/admins/edit/${params.row.id}`}><EditRoundedIcon /> {'   '}</Link>,
            <DeleteIcon onClick={() => onDeleteClick(params.row)} />
         ]},
];