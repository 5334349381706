import React from 'react';
import EditSponsorshipForm from './SponsorsEdit';
import Dialog from '../../../../../Components/Layout/Dialog';

interface Props { 
    open: boolean;
    onClose: () => void;
    sponsorshipId: string;
}

const ModalEditSponsor: React.FC<Props> = ({ open, onClose, sponsorshipId }) => {
    return (
        <Dialog open={open} title="Modifier un lien de parraiange" onClose={onClose}>
            <EditSponsorshipForm  onClose={onClose} sponsorshipId={sponsorshipId} />
        </Dialog>
    );
};

export default ModalEditSponsor;
