import type {GridValidRowModel} from "@mui/x-data-grid/models/gridRows";
import {GridColDef} from "@mui/x-data-grid";
import {dateFormatToDate} from "./DateUtil";

export type ApiColumn<T extends GridValidRowModel = any> = GridColDef<T> & {
    type?: GridColDef['type'] | 'date_api';
} & any;
//todo change any find the good interface.

export function generateBaseColumns<T extends GridValidRowModel = any>(columns: ApiColumn<T>[]): GridColDef[] {
    return columns.map((column, index) => {
        let definition: Partial<GridColDef>  = {};
        if (column.type === 'date_api') {
            definition.type = 'date';
            definition.valueGetter = (value) => dateFormatToDate(value);
        }

        definition.flex = (index === 0) ? 1 : undefined;

        return {
            ...column,
            ...definition,
            hideable: column.hideable || false,
            filterable: column.filterable || false,
            sortable: column.sortable || false,
        }
    })
}