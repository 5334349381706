import React from "react";
import {Formik, Form} from 'formik';
import { CreateAdmin } from "../../../Types/Admin";
import * as Yup from "yup";
import { Button, Grid } from "@mui/material";
import Input from "../../../Components/Form/Input";

interface Props{
    defaultValues?:Partial<CreateAdmin>;
    onSubmit: (admin: CreateAdmin) => void;
}
const AdminForm : React.FC<Props> =({ defaultValues,onSubmit }) =>{
    const initialValues: CreateAdmin ={
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        ...defaultValues,
    };

    const handleSubmit = (values: CreateAdmin) => {
        onSubmit(values);
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Le prénom est requis'),
        lastName: Yup.string().required('Le nom de famille est requis'),
        email: Yup.string().email('Veuillez entrer une adresse e-mail valide').required('L\'adresse e-mail est requise'),
        password: Yup.string().required('Le mot de passe est requis'),
    });

    return(
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} >
            <Form>
                <Grid container spacing={3}  direction="column" >
                    <Grid item>
                        <Input label="Prénom" name="firstName"/>
                    </Grid>
                    <Grid item>
                        <Input label="Nom" name="lastName"/>
                    </Grid>
                    <Grid item>
                        <Input label="Email" name="email"/>
                    </Grid>
                    <Grid item>
                        <Input label="Mot de passe" name="password"/>
                    </Grid>
                    <Grid item container justifyContent="center">
                        <Button type="submit" variant="contained">Valider</Button>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
}
export default AdminForm;