import React from 'react';
import {Box, Button, Grid, Typography} from '@mui/material';
import { Link } from 'react-router-dom';
import {useGetAllOrphanage} from '../../../Api/Queries/OrphanageQuery';
import ApiTable from "../../../Components/DataDisplay/ApiTable";
import {oprhanageColumns} from "./table";

const OrphanageList: React.FC = () => {
    return (
        <Box>
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Liste des Orphelinats</Typography>
            </Grid>

            <Grid container justifyContent="end" marginY={1}>
                <Grid>
                    <Link to="/orphanages/create">
                        <Button type="button" variant="contained" color="primary">Ajouter un orphelinat</Button>
                    </Link>
                </Grid>
            </Grid>

            <ApiTable query={useGetAllOrphanage} columns={oprhanageColumns} />
        </Box>
    );
};

export default OrphanageList;
