import {Dispatch, useMemo, useState} from "react";
import {GridPaginationModel} from "@mui/x-data-grid";

/**
 *
 * @param page
 * @param pageSize
 *
 *
 * api pagination is not the same because pagination with datagrid starts with 0 and 1 with api.
 *
 * @return [dataGridPagination, stateDataGridPagination, apiPagination]
 */
export const usePagination = (page: number = 0, pageSize: number = 40): [GridPaginationModel, Dispatch<GridPaginationModel>, GridPaginationModel] => {
    const [pagination, setPagination] = useState<GridPaginationModel>({page, pageSize})

    const apiPagination = useMemo(() => {
        return {...pagination, page: pagination.page + 1};
    }, [pagination.page]);

    return [pagination, setPagination, apiPagination];
}