import React, { useState } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import ApiTable from "../../../../../Components/DataDisplay/ApiTable";
import { SponsorshipColumns } from './Table';
import { useParams } from 'react-router-dom';
import { useGetAllSponsorships } from "../../../../../Api/Queries/SponsorshipQuery";
import { Sponsorship } from '../../../../../Types/Sponsorship';
import { useToggle } from "../../../../../Hooks/Transverse"; 
import ModalSponsor from '../../Sponsors/Create/ModalSponsor';
import ModalEditSponsor from '../../Sponsors/Edit/ModalEditSponsor';
import DeleteSponsor from '../Delete/DeleteSponsor';

const SponsorsList: React.FC = () => {
    const { id } = useParams<{ id?: string | undefined }>();
    const [selectedSponsor, setSelectedSponsor] = useState<Sponsorship | null>(null);
    const { isOn: openAddSponsorModal, on: showAddSponsorModal, off: hideAddSponsorModal } = useToggle();
    const { refetch } = useGetAllSponsorships();
    const { isOn: openEditSponsorModal, on: showEditSponsorModal, off: hideEditSponsorModal } = useToggle();
    const { isOn: isShownModalDelete, on: showModalDelete, off: hideModalDelete } = useToggle(false);

    const handleDeleteClick = (sponsor: Sponsorship) => {
        setSelectedSponsor(sponsor);
        showModalDelete();
    };

    const handleEditClick = (sponsorship: Sponsorship) => {
        setSelectedSponsor(sponsorship);
        showEditSponsorModal();
    };

    const handleCloseDeleteDialog = () => {
        setSelectedSponsor(null);
        hideModalDelete();
    };
    
    return (
        <Box>
            <Grid item marginTop={3}>
                <Button variant="contained" color="primary" onClick={showAddSponsorModal}>
                    Ajouter un parrain
                </Button>
            </Grid> 
            <ModalSponsor open={openAddSponsorModal} onClose={hideAddSponsorModal} orphan={id!} />
            {selectedSponsor && (
                <ModalEditSponsor open={openEditSponsorModal} onClose={hideEditSponsorModal} sponsorshipId={selectedSponsor.id!} />   
            )}
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Liste des Parrains</Typography>
            </Grid>
            <Grid item marginTop={3}>
                <ApiTable
                    query={useGetAllSponsorships}
                    columns={SponsorshipColumns({ onDeleteClick: handleDeleteClick, onEditClick: handleEditClick })}
                    additionalParams={id}
                />    
            </Grid>
            {selectedSponsor && (
                <DeleteSponsor
                    sponsor={selectedSponsor}
                    open={isShownModalDelete}
                    onClose={handleCloseDeleteDialog}
                    onDelete={refetch}
                />
            )}
        </Box>
    );
};

export default SponsorsList;
