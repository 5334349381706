import { Box, Button, Grid, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import React, { useState } from "react";
import ApiTable from "../../../Components/DataDisplay/ApiTable";
import { faqColumns } from "./Table";
import { useGetAllFaq } from "../../../Api/Queries/FaqQuery";
import Faq from "../../../Types/Faq";
import DeleteFaq from "../Delete/DeleteFaq";
import { useToggle } from "../../../Hooks/Transverse";

const FaqList: React.FC = () => {
    const [selecteFaq, setSelectedFaq] = useState<Faq |null>(null);
    const { isOn: isShownModalDelete, on: showModalDelete, off: hideModalDelete } = useToggle(false);
    const { refetch} = useGetAllFaq();

    const handleDeleteClick = (faq: Faq) => {
        setSelectedFaq(faq);
        showModalDelete();
    };

    const handleCloseDeleteDialog = () => {
        setSelectedFaq(null);
        hideModalDelete();
    };

    return(
        <Box>
            <Grid item marginY={5}>
                <Typography align="center" gutterBottom variant="h3">Liste des F.A.Q</Typography>
            </Grid>

            <Grid container justifyContent="end" marginY={1}>
                <Grid item>
                    <Link to="/faqs/create">
                        <Button type="button" variant="contained" color="primary">Ajouter une F.A.Q</Button>
                    </Link>
                </Grid>
            </Grid>

            <ApiTable query={useGetAllFaq} columns={faqColumns({ onDeleteClick: handleDeleteClick })} />

            {selecteFaq && (
                <DeleteFaq
                    faq={selecteFaq}
                    open={isShownModalDelete}
                    onClose={handleCloseDeleteDialog}
                    onDelete={refetch}
                />
            )}
        </Box>
    );
}
export default FaqList;