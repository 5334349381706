import React from 'react';
import Dialog from '../../../../../Components/Layout/Dialog';
import OrphanAssignmentPage from './OrphanAssignmentPage';

interface Props { 
    open: boolean;
    onClose: () => void;
    orphanage: string;
}

const ModalOrphanAssignment: React.FC<Props> = ({ open, onClose, orphanage }) => {
    return (
        <Dialog open={open} title="Ajouter un orphelin"  onClose={onClose}>
            <OrphanAssignmentPage orphanage={orphanage} />
        </Dialog>
    );
};

export default ModalOrphanAssignment;