import React from 'react';
import Box from '@mui/material/Box';
import OrphansStatistic from './Statistics/OrphansStatistic';
import OrphanagesStatistic from './Statistics/OrphanagesStatistic';

const Home: React.FC = () => {
  return (

      <Box display="flex" justifyContent="space-between">
        <OrphansStatistic />
        <OrphanagesStatistic />  
      </Box>

  );
};

export default Home;
