import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';
import { useGetStatisticsOrphans } from '../../../Api/Queries/StatisticQuery'; 
import Loading from '../../../Components/Feeback/Loading';


const OrphansStatistic: React.FC = () => {
  const { data: orphans,isLoading } = useGetStatisticsOrphans();

  if (isLoading) {
    return <Loading show={isLoading} title="Chargement en cours..." />;
  }

  return (
    <Box sx={{ marginTop: 5, boxShadow: 3, padding: 2, height: 300 }}>
      <h2>Nombre d'Orphelins</h2>
      <BarChart
        colors={['#03AED2']}
        series={[
          { data: [orphans.draft, orphans.published, orphans.unpublished, orphans.nb_orphanage] },
        ]}
        height={250}
        width={530}
        xAxis={[{ data: ['Brouillon', 'Publié', 'Non publié', 'Sans orphelinat'], scaleType: 'band' }]}
        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
      />
    </Box>
  );
}

export default OrphansStatistic;
