import React, {useMemo} from "react";
import {usePagination} from "../../Hooks/Datagrid";
import {DataGrid, GridPaginationModel} from "@mui/x-data-grid";
import {Container} from "@mui/material";
import {UseQueryResult} from "@tanstack/react-query";
import {Paginator} from "../../Types/Paginator";
import {ApiColumn, generateBaseColumns} from "../../Services/TabUtils";
import {GridValidRowModel} from "@mui/x-data-grid/models/gridRows";

interface Props<T extends GridValidRowModel = any> {
    /**
     * Function query like useGetAllOrphanage
     * @param pagination
     */
    query: (pagination: GridPaginationModel, additionalParams?:string) => UseQueryResult<Paginator<T>>;
    columns: ApiColumn<T>[];
    additionalParams?:string;
}

const ApiTable: React.FC<Props> = (props) => {
    const { query, additionalParams } = props;
    const [pagination, setPagination, apiPagination] = usePagination();
    const { data, isLoading } = query(apiPagination, additionalParams);
    const rowCountRef = React.useRef(data?.nbItem || 0);

    const columns = useMemo(() => {
        return generateBaseColumns(props.columns);
    }, [props.columns]);

    const rowCount = React.useMemo(() => {
        if (data?.nbItem !== undefined) {
            rowCountRef.current = data?.nbItem || 0;
        }

        return rowCountRef.current;
    }, [data?.nbItem]);

    return <DataGrid
        loading={isLoading}
        paginationMode="server"
        onPaginationModelChange={setPagination}
        pageSizeOptions={[20, 40]}
        paginationModel={pagination}
        columns={columns}
        rows={data?.results ?? []}
        rowCount={rowCount}
    />
}

export default ApiTable;